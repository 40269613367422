// import React from "react";

// function EditIcon() {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="30"
//       height="30"
//       fill="none"
//       viewBox="0 0 24 24"
//       // className="md:w-18-scale-924 md:h-18-scale-924 w-18-scale-mob h-18-scale-mob"
//       // className={`w-24-scale-mob md:w-24-scale-924 h-24-scale-mob md:h-24-scale-924`}

//     >
//       <path
//         stroke="#000"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth="1.2"
//         d="M5.25 5.25H4.5A1.5 1.5 0 003 6.75v6.75A1.5 1.5 0 004.5 15h6.75a1.5 1.5 0 001.5-1.5v-.75"
//       ></path>
//       <path
//         stroke="#000"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth="1.2"
//         d="M12 3.75L14.25 6m1.039-1.061A1.575 1.575 0 0013.06 2.71L6.75 9.001v2.25H9l6.289-6.312z"
//       ></path>
//     </svg>
//   );
// }

// export default EditIcon;

import React from "react";

function Icon({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="19"
      // height="19"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      className={`w-18-scale-mob md:w-18-scale-924 h-20-scale-mob md:h-20-scale-924 ${className}`}

    >
      <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7"></path>
      <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z"></path>
    </svg>
  );
}

export default Icon;