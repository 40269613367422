import React from 'react'
import MedicalConditionIcon from '../../../icons/HealthCareIcon';
//redux
import { useSelector } from 'react-redux';
import { formatString } from '../../../methods';
import PillsIcon from '../../../icons/PillsIcon';
import CareIcon from '../../../icons/CareIcon';
import SideNav from './SideNav';
import { useNavigate, useParams } from 'react-router-dom';

const MedicalInfo = () => {
    const formData = useSelector((state) => state.users.user_details);
const navigate=useNavigate();
const params=useParams();
const {id}=params;
const other_conditions=formData && formData.hobbies && formData.medical_conditions.find(val => val.medical_condition === "other_conditions")

    return (<div className="flex justify-between flex-col md:flex-row">
            <div className='md:w-[20%]'>
            <SideNav />
            </div>
        <div className='md:w-[77%] flex flex-col form-fields pb-20-scale-mob md:pb-20-scale-924'>
            <div className="flex justify-end">
                <button type="button" className="md:h-30-scale-924 h-30-scale-mob w-158-scale-mob md:w-158-scale-924 border border-1 border-black-100 rounded-[6px]  text-14-scale-mob md:text-14-scale-924 bg-[#F5F5F5]" onClick={()=>navigate(`/user/add/${id}/medicalinfo`)}>Edit details</button>
            </div>
            <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob flex flex-col form-gap-20">
                <div>
                    <div className="flex items-center gap-x-[10px] md:pb-20-scale-924 pb-20-scale-mob ">
                        <MedicalConditionIcon />
                        <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[10px]'>Medical condition</p>

                    </div>
                    <div className="flex flex-wrap form-fields ">
                        {formData && formData.medical_conditions?.map(val => (
                            <div className="w-[100%] md:w-[30%] bg-white-100 md:p-10-scale p-10-scale flex flex-col gap-y-[10px] rounded-[5px] " key={val.medical_condition}>
                                {/* <p>hello</p>  */}
                                <p className="md:text-14-scale-924 text-14-scale-mob font-[600]">{val.medical_condition && formatString(val.medical_condition)}</p>
                                <p className="md:text-14-scale-924 text-14-scale-mob">{val["year"]?`${val["year"]} years`:"-"}</p>
                            </div>

                        ))}

                    </div>
                </div>
                {other_conditions && <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px]">
                                        <p className="md:text-14-scale-924 text-14-scale-mob">{other_conditions.other_diseases||"-"}</p>
                                    </div>}

            </div>
            <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob flex flex-col form-gap-20">

                <div className="flex items-center gap-x-[10px]  ">
                    <PillsIcon />
                    <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[10px]'>Medication</p>

                </div>

                {/* {formData && formData.medicines && formData.medicines.map(val => (
                    <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] flex justify-between" key={val.condition}>
                        <div>
                            <p className="md:text-14-scale-924 text-14-scale-mob font-[700]">{val.condition&&formatString(val.condition)}</p>
                            <p className="md:text-14-scale-924 text-14-scale-mob ">{val.medicine}</p>
                        </div>
                        <div className="bg-black-100 md:py-10-scale-924 py-10-scale-mob  md:px-30-scale-924 px-30-scale-mob rounded-[10px]">
                            <p className="text-white-100 md:text-14-scale-924 text-14-scale-mob font-[600]">Dosage Frequency</p>
                            <p className="text-white-100 md:text-14-scale-924 text-14-scale-mob font-[600]">{val.dosage_frequency}</p>
                        </div>
                    </div>))} */}
                {formData && formData.medications && formData.medications.map(val => (
                  (val.condition|| val.medicine_name)&& <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] flex justify-between" key={val.condition}>
                        <div>
                            <p className="md:text-14-scale-924 text-14-scale-mob font-[700]">{val.condition && formatString(val.condition)}</p>
                            <p className="md:text-14-scale-924 text-14-scale-mob ">{val.medicine_name}</p>
                        </div>
                        <div className="bg-black-100 md:py-10-scale-924 py-10-scale-mob  md:px-30-scale-924 px-30-scale-mob rounded-[10px]">
                            <p className="text-white-100 md:text-14-scale-924 text-14-scale-mob font-[600]">Dosage Frequency</p>
                            <p className="text-white-100 md:text-14-scale-924 text-14-scale-mob ">{val.dosage_frequency}</p>
                        </div>
                    </div>))}

            </div>
           
            <div className="bg-white-100 rounded-[10px] flex flex-col ">
                <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob flex flex-col form-gap-20">

                    <div className="flex items-center gap-x-[10px] ">
                        <CareIcon />
                        <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[10px]'>Professional caregiver team</p>

                    </div>

                  
                    {formData && formData.professional_caregiver_team && formData.professional_caregiver_team.map(val => (
                    (val.expert_name||val.expert_type||val.expert_name)  &&   <div className="flex justify-between flex-wrap gap-y-[15px]" key={val.expert_name} >
                            <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px]  text-14-scale-mob md:text-14-scale pb-20-scale-mob md:pb-20-scale-924 flex flex-col gap-[10px] w-[22%]" >
                              <p className="text-14-scale-mob md:text-14-scale-924 font-[700]">Expert type</p>
                              <p className="text-14-scale-mob md:text-14-scale-924">{val["expert_type"]?formatString(val["expert_type"]):"-"}</p>
                            </div>
                            <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px]  text-14-scale-mob md:text-14-scale pb-20-scale-mob md:pb-20-scale-924 flex flex-col gap-[10px] w-[22%]" >
                              <p className="text-14-scale-mob md:text-14-scale-924 font-[700]">Expert name</p>
                                <p className="text-14-scale-mob md:text-14-scale-924">{val["expert_name"]||"-"}</p>
                            </div>
                            <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px]  text-14-scale-mob md:text-14-scale pb-20-scale-mob md:pb-20-scale-924 flex flex-col gap-[10px] w-[22%]" >
                              <p className="text-14-scale-mob md:text-14-scale-924 font-[700]">Expertise</p>
                                <p className="text-14-scale-mob md:text-14-scale-924">{val["expertise"]||"-"}</p>
                            </div>
                            <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px]  text-14-scale-mob md:text-14-scale pb-20-scale-mob md:pb-20-scale-924 flex flex-col gap-[10px] w-[22%]" >
                              <p className="text-14-scale-mob md:text-14-scale-924 font-[700]">Expert Number</p>
                                <p className="text-14-scale-mob md:text-14-scale-924">{val["phone_number"]||"-"}</p>
                            </div>
                            </div>
                       ))}
                       
                </div>
            </div>
        </div>
        </div>
    )
}

export default MedicalInfo