import React, {  useEffect, useState, useCallback } from 'react'
import NavBar from '../../components/navbar/NavBar'
import GlobalTable from '../../components/Table/GlobalTable';
import "./users.css"
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import { getAllPrivateContacts ,addNotes,makeCall} from '../../redux/actions/usersactions';
import moment from 'moment';
import EyeIcon from '../../icons/EyeIcon';
import { debounce } from 'lodash';
import Pagination from '../../components/pagination/Pagination';
import GlobalInput from '../../components/GlobalInput';
import SearchIcon from '../../icons/SearchIcon';
import { compareTime } from '../../methods';
import { toast } from 'react-toastify';
import GlobalTextArea from '../../components/GlobalTextArea';
import CloseIcon from '../../icons/CloseIcon';
import AddNoteIcon from '../../icons/AddNoteIcon';
import MakeCallIcon from '../../icons/MakeCallIcon';


const UserManagement = () => {
  const token = localStorage.getItem("token")
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const contacts = useSelector((state) => state.users.contacts)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [userId, setUserId] = useState("");
  const [notesLoading, setNotesLoading] = useState(false)
  const methods = useForm({
    mode: "all",
    defaultValues: { user_type: { label: "New Users", value: "new" } }
  }
  );
  const methods1 = useForm({
    mode: "all",
    defaultValues: { user_type: { label: "New Users", value: "new" } }
  }
  );
  const { control, formState: { errors }, setValue, getValues, reset } = methods;
  const { control: control1, formState: { errors: errors1 }, reset: reset1 } = methods1;
  const handleMakeCall = async (id) => {
    try {
      await dispatch(makeCall(id))      
      toast.success("Call Initiated Successfully")
    } catch (err) {
      console.log(err.message);
      toast.error(err.message)
    }
  }

  const submitNotes = async (data) => {
    setNotesLoading(true)

    try {
      await dispatch(addNotes(userId, { note: data.note, tag: "call" }));    
      document.getElementById('my_modal_8').close();

      reset1()
      setUserId("")
      toast.success("Note added successfully")

      // navigate(`/user/add/${id}/surgicalinfo/${details_id}`)
    } catch (err) {
      console.log(err)
      toast.error(err.message)
    }
    setNotesLoading(false)
  }
 
 

  const headings = [{ name: "Unique ID", value: "id" }, { name: "Date of adding", value: "date_of_adding", render: (val) => <p>{moment.utc(val.created_at).format('MMMM DD, YYYY')}</p> },
  { name: "Name", value: "full_name" }, { name: "Agent assigned", value: "agent_name" },
  { name: "Age", value: "age" }, { name: "Phone number", value: "phone_number",render: (val) => <p>{val.phone_number && val.phone_number.replace(/^91/, '')}</p> }, { name: "Date registered", value: "created_at", render: (val) => <div>{val.registration_date&&moment.utc(val.registration_date).format("MMMM DD,YYYY")}</div>, sort: true }, 
  { name: "Last call", value: "last_call_date", render: (val) => <p>{val.last_call_date ? moment.utc(val.last_call_date).format('MMMM DD, YYYY') : "-"}</p>},
  { name: "Last interaction", value: "last_interaction", render: (val) => <p className={`${val.last_interaction_date&&compareTime(val.last_interaction_date)==="Greater than 48 hours"?"text-[red]":"text-[black]"} `}>{val.last_interaction_date ? compareTime(val.last_interaction_date) : "-"}</p>},
  { name: "Upcoming call", value: "upcoming_call",render: (val) => <p>{val.followup_date ? moment.utc(val.followup_date).format('MMMM DD, YYYY') : "-"}</p> },

  {
    name: "Actions", headingStyles: "text-center", render: (val) => <div className="flex gap-x-[10px] justify-center items-center">

      <>
       <div className="tooltip" data-tip="Make A call">
          <button onClick={() => handleMakeCall(val.id)}><MakeCallIcon /> </button>
        </div>
        <div className="tooltip" data-tip="View details">
        <button onClick={() => navigate(`/user/${val.id}`)}><EyeIcon /></button>
        </div>
        <div className="tooltip" data-tip="Add note">
          <button onClick={() => {
            setUserId(val.id);
             document.getElementById('my_modal_8').showModal();
          }}><AddNoteIcon /></button>
        </div>
      </>

    </div>
  }]

  //fetchContacts
  const fetchContacts = async () => {
    try {
      setLoading(true)
      await dispatch(getAllPrivateContacts(currentPage, "registered", sort, search))


    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchContacts()
  }, [currentPage, sort, search])
  const handleSort = (order) => {
    // setSort(sort==="-updated_at"?"updated_at":"-updated_at")
    if (order === -1) {
      setSort("-created_at")
    } else {
      setSort("created_at")
    }
  }
  const handleSearch = (e) => {
    if (e.code === "Enter") {
      setSearch(getValues("search"))
    }
  }
  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(getValues("search"));
      setCurrentPage(1)
    }, 500),
    []
  );
  if (!token) {
    navigate("/")
  }
  return (
    <>
    <div>
      <NavBar />
      <div className="flex flex-col md:flex-row pt-91-scale-mob md:pt-91-scale justify-between px-50-scale">
        <div className="w-[100%]">
          <div className='flex justify-between pb-[20px] flex-col md:flex-row gap-[20px] md:gap-[0px]'>
            <h1 className="text-24-scale-mob md:text-24-scale font-inter font-[600] ">User Table</h1>

            {/* <p className="absolute inset-y-0 left-2  flex items-center pr-[15px]  text-16-scale-mob md:text-16-scale-924 font-600 border-r-2 border-black-100   font-[600]"> */}
            <div className="relative" >
              <div className="absolute inset-y-0 left-2  flex items-center z-[20] "> <SearchIcon /></div>
              <GlobalInput control={control} name="search" search={true} className="border-[#E4E4E7] text-black-100  md:!w-[400px]" placeholder="Search by user name or ID here"
                onKeyPress={(e) => handleSearch(e)} onChange={debouncedSearch} />
            </div>

          </div>

          <div className="custom-box-shadow rounded-[10px] pl-[15px] ">
            {/* <GlobalTable headings={headings}
              data={contacts ? contacts.results : []}
              // data={[]}
              loading={loading}
              handleSort={handleSort}
              sort={sort}
            /> */}
            <GlobalTable headings={headings}
            isSticky={true}
              sort={sort}
              handleSort={handleSort}
              data={contacts ? contacts.results : []}
              loading={loading}
            />
          </div>

        </div>




      </div>
      {!loading && <div className="py-20-scale-mob md:py-20-scale-924">
        <Pagination
          totalCount={
            contacts ? contacts.count : 0
          }
          currentPage={currentPage}
          pageSize={10}
          onPageChange={((currentPage) => {
            setCurrentPage(currentPage)
          })}

        />
      </div>}


    </div>
      {/* Notes Modal */}
      <dialog className="modal rounded-[0]" id="my_modal_8">
        <div className="modal-box w-[95%] md:min-w-[800px] p-0 rounded-[0]">
          <div className="flex justify-between border-b-[1px] border-[black] items-center py-[15px] px-[15px]" >
            <h3 className="md:text-18-scale-924 text-18-scale-mob font-inter ">Add notes - <span className="font-[600]">{moment(Date.now()).format("DD MMM YYYY")}</span></h3>

            <button onClick={() => { document.getElementById('my_modal_8').close(); reset1(); setUserId("") }} ><CloseIcon /></button>

          </div>
          <div className="py-[15px]">


            <GlobalTextArea control={control1} name="note" required={true}
              rules={{ required: "Please enter note" }} errors={errors1.note}

              placeholder="Type your notes here"
              //  className="font-[!400] !border-0 note-input"
              className="h-[300px] note-input"
            />


            <hr className="border-black-100" />
            <div className="flex justify-end pt-[20px] px-[20px] gap-[20px]">
              <button className="border border-black-100 w-[168px] md:w-[168px] h-[43px] rounded-[4px] font-[600]" onClick={() => { document.getElementById('my_modal_8').close(); reset1(); setUserId("") }}>Discard</button>

              <button className="bg-[black] opacity-[50%] text-[white] w-[168px] md:w-[168px] h-[43px] rounded-[4px]" onClick={methods1.handleSubmit(submitNotes)} disabled={loading}>{notesLoading ? <span className="loading loading-spinner loading-sm"></span> : "Add"}</button>
            </div>

          </div>
          {/* <hr className="bg-[black] text-[black] color-[black]"/> */}
        </div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={() => reset1()}>close</button>
        </form>
      </dialog>
    </>
  )
}

export default UserManagement