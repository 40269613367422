import React, { useEffect, useState, useRef } from 'react'

import '../../../DetailsForm/form.css'
import SocialMediaIcon from '../../../../icons/SocialMediaIcon'
import { useDispatch, useSelector } from 'react-redux'
import "./chat.css"
import { editChat, getChat, getCompanionMode, sendFeedback, sendMsg, updatechatmode } from '../../../../redux/actions/commonActions'
import Chat from '../../../../components/chat/Chat'
import { useForm } from 'react-hook-form'
import CloseIcon from '../../../../icons/CloseIcon'
import { toast } from 'react-toastify'
import GlobalInput from '../../../../components/GlobalInput'
import SendIcon from '../../../../icons/SendIcon'

const modes = [
    {
        id: 1,
        heading: "Manual override",
        mode: "manual",
        desc: "Aayu shares responses and you can review them, approve/reject them, or replace them with your own before sending to the user."
    },
    {
        id: 2,
        heading: "Full control",
        mode: "override",
        desc: "Take full control of bot-initiated conversations by shutting down Aayu to send intervention messages."
    }, {
        id: 3,
        mode: "auto",
        heading: "Auto mode",
        desc: "Aayu stays active and fully manages conversations without manual intervention."
    }
]
const ChatManagement = () => {
    const chatContainerRef = useRef(null);
    const hasScrolled = useRef(false);
    const dispatch = useDispatch();
    const formData = useSelector((state) => state.users.user_details);
    const chatHistory = useSelector((state) => state.common.chat);
    const [editChatId, setEditChatId] = useState("")
    const [isChecked, setIsChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [campanionMode, setCampanionMode] = useState("")
    const methods = useForm({
        mode: "all",

    }
    );
    const { control, formState: { errors }, reset, getValues } = methods;
    //featching campanion mode
    const getCampanionMode = async (closeModal = false) => {
        if (formData?.phone_number) {
            try {
                const response = await dispatch(getCompanionMode(formData?.phone_number))
                const mode = response?.companion_mode
                setIsChecked(mode)

                if (closeModal) {
                    document.getElementById('my_modal_8')?.close()
                }
                return response
            } catch (err) {
                console.log(err.message)
            }
        }
    }
    const fetchChatHistory = async () => {
        try {
            await (dispatch(getChat(formData?.phone_number)))
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        let intervalId;

        const initialize = async () => {
            const response = await getCampanionMode();
            if (response?.companion_mode === null) {
                setCampanionMode("Test User")
            }

            if (formData?.phone_number && response?.companion_mode !== null) {
                fetchChatHistory(); // initial call
                intervalId = setInterval(fetchChatHistory, 5000); // set interval
            }
        };
        initialize(); // Call the async function
        return () => {
            if (intervalId) {
                clearInterval(intervalId); // Cleanup interval when unmounted
            }
        };
    }, [formData?.phone_number]);




    useEffect(() => {
        if (chatContainerRef.current && !hasScrolled.current && chatHistory) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            hasScrolled.current = true;
        }
    }, [chatHistory]);

    //edit chat
    const handleEditMsg = (id, content) => {
        setEditChatId(id);
        reset({ [`message_${id}`]: content })

    }
    const handleSave = async (id, message) => {

        const logedinuser = localStorage.getItem("phone_number")

        try {
            await dispatch(editChat({ message_id: id, message: message || getValues(`message_${id}`), staff_phone_number: logedinuser }))
            await fetchChatHistory()
            toast.success("message save successfully")
            setEditChatId("")
        } catch (err) {
            console.log(err)
            toast.error(err.message)
        }
    }
    const handleRevert = () => {
        setEditChatId("")
    }
    const handleChecked = (value) => {
        console.log(value)
        setIsChecked(value)
        if (value === "manual" || value === "fully_control") {
            document.getElementById('my_modal_8')?.showModal()
        } else {
            changeManagementStatus(value)
        }
    }

    const changeManagementStatus = async (checked) => {
        setLoading(true)
        try {
            await dispatch(updatechatmode({ companion_mode: checked, phone_number: formData?.phone_number }))
            getCampanionMode()
            document.getElementById('my_modal_8')?.close()
            toast.success("Mode changed successfully")
        } catch (err) {
            console.log(err.message)
            toast.error(err.message)

        }
        setLoading(false)
    }
    const handleSendFeedback = async (id, feedback) => {
        const logedinuser = localStorage.getItem("phone_number")

        try {
            await dispatch(sendFeedback({
                message_id: id,
                feedback_type: feedback,
                staff_phone_number: logedinuser
            }))
            toast.success("Feedback saved successfully")
        } catch (err) {
            console.log(err)
            toast.error(err.message)
        }
    }
    const handleSendMsg = async () => {
        const logedinuser = localStorage.getItem("phone_number")
        const phone_number= formData?.phone_number
        // console.log({message:getValues("message"),staff_phone_number:logedinuser})
        try {
            await dispatch(sendMsg({ message: getValues("message"), staff_phone_number: logedinuser, phone: phone_number }))
            reset({})
        } catch (err) {
            console.log(err.message)
            toast.error(err.message)
        }

    }
    //handle enter press to send msg
    const handleKeyPress = (e) => {
        if (e.code === "Enter") {
            handleSendMsg()
        }
    }
    return (
        <>
            <div className="flex flex-col form-fields pb-[20px]">
                <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob flex flex-col form-gap-20 ">
                    <div className="flex flex-col gap-[10px]">
                        <div className="flex items-center gap-[10px]">
                            <SocialMediaIcon />
                            <p className="md:text-24-scale-924 text-24-scale-mob font-[600]">Chat management </p>
                        </div>
                        <p className="text-14-scale-mob md:text-14-scale-924">Select any one option</p>
                    </div>

                    {campanionMode ? <div className="bg-white-100 border border-[#EBEBEB] rounded-[8px] shadow-sm p-20-scale-924  flex flex-col gap-[5px]">

                        <p className="text-14-scale-mob md:text-14-scale-924 text-[red]">{campanionMode}</p>

                    </div>
                        :
                        <div className='flex justify-between flex-wrap gap-[10px] md:gap-0'>
                            {modes.map(val => <div className="md:w-[30%] p w-[100%] bg-white-100 border border-[#EBEBEB] rounded-[8px] shadow-sm p-20-scale-924  flex flex-col gap-[5px]" key={val.id}>

                                <div className="flex justify-between">
                                    <p className="md:text-18-scale-924 text-18-scale-mob font-[700]">{val.heading}</p>
                                    {/* <label class="switch"> */}
                                    <input type="radio" checked={isChecked === val.mode} value={val.mode} name="mode" onChange={(event) => handleChecked(event.target.value)} className="w-[30px] h-[30px] custom-radio " />
                                    {/* <span class="slider round"></span> */}
                                    {/* </label> */}
                                </div>
                                <p className="text-14-scale-mob md:text-14-scale-924 text-[#09090B]">{val.desc}</p>
                            </div>)}
                        </div>}


                </div>
                {!campanionMode && <>
                    <p className="text-24-scale-mob md:text-24-scale-924 font-[700] ">Chat History</p>
                    <div className="relative min-h-[500px] w-[100%]  bg-[#F6F6F6] ">
                        <div className={`rounded-[10px] flex flex-col gap-[20px] p-[40px] min-h-[450px] ${isChecked==="override"?"max-h-[450px]":"max-h-[500px]"} overflow-y-auto`} ref={chatContainerRef} >

                            {chatHistory?.map((val, index) => <Chat data={val} key={val.message_id} index={index} length={chatHistory.length} handleEditMsg={handleEditMsg} editChatId={editChatId} control={control} handleSave={handleSave} handleRevert={handleRevert} handleSendFeedback={handleSendFeedback} checked={isChecked} />)}

                        </div>
                     {isChecked==="override" &&<div className="absolute  bottom-[30px] flex  w-[100%] justify-center  py-[10px]">
                            <div className="relative h-[45px] shadow-sm rounded-[24px]">
                                <GlobalInput name="message" control={control} placeholder="Type Message..." className="md:!w-1200-scale-924 !w-300-scale-mob !rounded-[24px] !border-0 " onKeyPress={(e) => handleKeyPress(e)} />
                                <button className='absolute right-3 top-1/2 -translate-y-1/2' onClick={handleSendMsg}>
                                    <SendIcon />
                                </button>
                            </div>
                        </div>}
                    </div>
                </>}
            </div>
            {/* confirmation Modal */}
            <dialog className="modal rounded-[0]" id="my_modal_8">
                <div className="modal-box max-w-[700px]  p-0 rounded-[10px] ">
                    <div className="flex justify-between border-b-[1px] border-[black] items-center py-[15px] px-[15px]" >
                        <h3 className="md:text-24-scale-924 text-24-scale-mob  font-[600] ">Manual Override</h3>

                        <button onClick={() => getCampanionMode(true)}><CloseIcon /></button>

                    </div>
                    <div className="p-[20px] flex flex-col gap-[20px]">
                        <p className="text-18-scale-mob md:text-18-scale-924">Are you sure you want to manually take over the control of Aayu’s messages?<br />
                            Please note that turning this on, will turn the other mode off automatically.</p>
                        <button className="border border-2 bg-black-primary border-black-primary  w-148-scale-mob md:w-148-scale h-47-scale-924-mob  md:h-47-scale-924 text-16-scale-mob md:text-16-scale-924  text-white-100 place-self-end"
                            onClick={() => changeManagementStatus(isChecked)}>{loading ? "loading..." : "Yes override"}</button>

                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
                    <button onClick={() => getCampanionMode(true)}>close</button>
                </form>
            </dialog>
        </>
    )
}

export default ChatManagement
