import * as events from "../Events";
const initialState = {
  provinces:null,
  cities:null,
  aiinsights:null,
  chat:null,


};
export const commonReducers = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_PROVINCE:
      return {
        ...state,
        provinces:action.payload
      };
      case events.GET_CITIES:
        return {
          ...state,
          cities:action.payload
        };
        case events.GET_AI_INSIGHTS:
          return {
            ...state,
            aiinsights:action.payload
          };
          case events.GET_CHAT:
            return {
              ...state,
              chat:action.payload
            };
            
        
    default:
      return state;
  }
};
