import React from "react";

function TickIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
    //   width="15"
    //   height="15"
      fill="none"
      viewBox="0 0 15 15"
      className="w-15-scale-mob md:w-15-scale-924 h-15-scale-mob md:h-15-scale-924"
    >
      <path
        stroke="#000"
        d="M4 7.5L7 10l4-5m-3.5 9.5A7.002 7.002 0 012.55 2.55 7 7 0 117.5 14.5z"
      ></path>
    </svg>
  );
}

export default TickIcon;