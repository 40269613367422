import * as React from "react";

const SendIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    fill="none"
    viewBox="0 0 20 18"
  >
    <path
      fill="#09090B"
      d="M1.18 17.927a.8.8 0 0 1-.8-.074.82.82 0 0 1-.38-.725v-5.911L8.483 9 0 6.724V.871Q0 .4.38.147a.8.8 0 0 1 .8-.074l18.314 8.1Q20 8.408 20 9t-.506.828z"
    ></path>
  </svg>
);

export default SendIcon;
