import moment from "moment";
import * as events from "../Events";
const token=localStorage.getItem("token")

export const getAllUsers = (page) => {
    // console.log(token)
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/users/`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
          }
       
      });
      if (!response.ok) {
        const resError = await response.json();   
         
        if (resError) {
         
          throw new Error(resError);
        }
      } else {
        const resData = await response.json();

        // const users = resData;
        dispatch({ type: events.GET_ALL_USERS,payload:resData });
        return resData ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//adduser
export const addUser = (data) => {
    // console.log(token)
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/user-details/`, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
          },
          body: JSON.stringify(data),
      });
      if (!response.ok) {
        const resError = await response.json();
        let jsonError=""
        if(typeof(resError)==="object"){
          jsonError= JSON.stringify(resError)
        }else{
          jsonError=resError
        }
        if (jsonError) {
          throw new Error(jsonError);
        }
       
      } else {
        const resData = await response.json();

        // const users = resData;
        dispatch({ type: events.ADD_USER,payload:resData });
        return resData ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//private contacts
export const getAllPrivateContacts = (page,status,sort,search,formStatus) => {
    const url=search?
    `${events.API_URL}/user-details/?page=${page}&registration_status=${status}&ordering=${sort}&search=${search}&${formStatus ? `&form_status=${formStatus}`:""}`:
    `${events.API_URL}/user-details/?page=${page}&registration_status=${status}&ordering=${sort}${formStatus ? `&form_status=${formStatus}`:""}`
    // console.log(token)
  return async (dispatch) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
          }
      });
      if (!response.ok) {
        const resError = await response.json();
        if (resError) {
          if(resError.code==="token_not_valid"){
            localStorage.clear();          
            
          }
          throw new Error(resError);
        }
      } else {
        const resData = await response.json();

        // const users = resData;
        dispatch({ type: events.GET_ALL_PRIVATE_CONTACTS,payload:resData });
        return resData ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//add user Details
export const addUserDetails = (id,data) => {
return async (dispatch) => {
  try {
    const response = await fetch(`${events.API_URL}/user-details/?private_contact_id=${id}`, {
      method: "POST",
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json' 
        },
        body:JSON.stringify(data)
    });
    if (!response.ok) {
      const resError = await response.json();
      if (resError) {
        throw new Error(resError);
      }
     
    } else {
      const resData = await response.json();

      // const users = resData;
      dispatch({ type: events.ADD_USER_DETAILS,payload:resData });
      return resData ? resData : false;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};
};

//patch method for user details

export const editUserDeatils = (id,data) => {
return async (dispatch) => {
  try {
    const response = await fetch(`${events.API_URL}/user-details/${id}/`, {
      method: "PATCH",
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json' 
        },
        body:JSON.stringify(data)
    });
    if (!response.ok) {
      const resError = await response.json();
      let jsonError=""
      if(typeof(resError)==="object"){   
        jsonError= JSON.stringify(resError)
      }else{
        jsonError=resError
      }
      if (jsonError) {
        throw new Error(jsonError);
      }
    } else {
      const resData = await response.json();

      // const users = resData;
      dispatch({ type: events.EDIT_USER_DETAILS,payload:resData });
      return resData ? resData : false;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};
};

//get user details
export const getUserDetails = (id) => {
return async (dispatch) => {
  try {
    const response = await fetch(`${events.API_URL}/user-details/${id}`, {
      method: "GET",
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json' 
        }
     
    });
    if (!response.ok) {
      
      const resError = await response.json();
      if (resError) {
        if(resError.code==="token_not_valid"){
          localStorage.clear();          
          
        }
        throw new Error(resError);
      }
    } else {
      const resData = await response.json();

      // const users = resData;
      dispatch({ type: events.GET_USER_DETAILS,payload:resData });
      return resData ? resData : false;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};
};

//get single user details
export const getPrivateDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/user_details/${id}`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
          }
       
      });
      if (!response.ok) {
        const resError = await response.json();
        if (resError) {
          throw new Error(resError);
        }
      } else {
        const resData = await response.json();
  
        // const users = resData;
        dispatch({ type: events.GET_SINGLE_USER_DETAILS,payload:resData });
        return resData ? resData : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
  };

  //add notes
  export const addNotes = (id,data) => {
    return async (dispatch) => {
      try {
        const response = await fetch(`${events.API_URL}/notes/?user_id=${id}`, {
          method: "POST",
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json' 
            },
            body:JSON.stringify(data)
        });
        if (!response.ok) {
          const resError = await response.json();
          let jsonError=""
          if(typeof(resError)==="object"){
            jsonError= JSON.stringify(resError)
          }else{
            jsonError=resError
          }
          if (jsonError) {
            throw new Error(jsonError);
          }
        } else {
          const resData = await response.json();
          // const users = resData;
          dispatch({ type: events.ADD_NOTES,payload:resData });
          return resData ? resData : false;
        }
      } catch (err) {
        throw new Error(err.message);
      }
    };
    };
    export const editNotes = (id,data) => {
      return async (dispatch) => {
        try {
          const response = await fetch(`${events.API_URL}/notes/${id}/`, {
            method: "PATCH",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json' 
              },
              body:JSON.stringify(data)
          });
          if (!response.ok) {
            const resError = await response.json();
            let jsonError=""
            if(typeof(resError)==="object"){
              jsonError= JSON.stringify(resError)
            }else{
              jsonError=resError
            }
            if (jsonError) {
              throw new Error(jsonError);
            }
          } else {
            const resData = await response.json();
      
            // const users = resData;
            dispatch({ type: events.EDIT_NOTES,payload:resData });
            return resData ? resData : false;
          }
        } catch (err) {
          throw new Error(err.message);
        }
      };
      };
      
      export const getAllNotes = (id) => {
        // console.log(token)
      return async (dispatch) => {
        try {
          const response = await fetch(`${events.API_URL}/notes/?user_id=${id}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json' 
              }
           
          });
          if (!response.ok) {
            const resError = await response.json();
            if (resError) {
              throw new Error(resError);
            }
          } else {
            const resData = await response.json();
    
            // const users = resData;
            dispatch({ type: events.GET_ALL_NOTES,payload:resData });
            return resData ? true : false;
          }
        } catch (err) {
          throw new Error(err.message);
        }
      };
    };
    

    //stats
    export const getStats = (startDate,endDate,type) => {
      // console.log(token)
      const url=startDate?`${events.API_URL}/user-details/stats/?created_at_after=${startDate||null}&created_at_before=${endDate}`:
      `${events.API_URL}/user-details/stats/?created_at_before=${endDate}`;
      const followUpUrl=`${events.API_URL}/followups/stats/?followup_at=${endDate}`;

    return async (dispatch) => {
      try {
        const response = await fetch(type==="followup"?followUpUrl:url, {
          method: "GET",
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json' 
            }
         
        });
        if (!response.ok) {
          const resError = await response.json();
          if (resError) {
            throw new Error(resError);
          }
        } else {
          const resData = await response.json();
  
          // const users = resData;
          dispatch({ type: events.STATS,payload:resData });
          return resData ? true : false;
        }
      } catch (err) {
        throw new Error(err.message);
      }
    };
  };

  //call status
  export const updateCallStatus = (id,data) => {
    return async (dispatch) => {
      try {
        const response = await fetch(`${events.API_URL}/user-details/${id}/`, {
          method: "PUT",
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json' 
            },
           body:data?JSON.stringify(data):JSON.stringify({call_status:"missed"})
        });
        if (!response.ok) {
          const resError = await response.json();
          let jsonError=""
          if(typeof(resError)==="object"){
            jsonError= JSON.stringify(resError)
          }else{
            jsonError=resError
          }
          if (jsonError) {
            throw new Error(jsonError);
          }
        }  else {
          const resData = await response.json();
        
          // const users = resData;
          dispatch({ type: events.CALL_STATUS,payload:resData});
          return resData ? resData : false;
        }
      } catch (err) {
        throw new Error(err.message);
      }
    };
    };
    // FOLLOW_UP_CALL_STATUS
    export const updateFollowupCallStatus = (id) => {
      return async (dispatch) => {
        try {
          const response = await fetch(`${events.API_URL}/followups/${id}/`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json' 
              },
             body:JSON.stringify({status:"missed"})
          });
           if (!response.ok) {
            const resError = await response.json();
            let jsonError=""
            if(typeof(resError)==="object"){
              jsonError= JSON.stringify(resError)
            }else{
              jsonError=resError
            }
            if (jsonError) {
              throw new Error(jsonError);
            }
          } else {
            const resData = await response.json();
      
            // const users = resData;
            dispatch({ type: events.FOLLOW_UP_CALL_STATUS,payload:resData});
            return resData ? resData : false;
          }
        } catch (err) {
          throw new Error(err.message);
        }
      };
      };
  // audio upload
  export const uploadAudio=(data)=>{
    return async (dispatch) => {
      try {
        const response = await fetch(`${events.API_URL}/files/`, {
          method: "POST",
          headers: {
              'Authorization': `Bearer ${token}`,
              // 'Content-Type': 'application/json' 
            },
           body:data
        });
        if (!response.ok) {
          const resError = await response.json();
          let jsonError=""
          if(typeof(resError)==="object"){
            jsonError= JSON.stringify(resError)
          }else{
            jsonError=resError
          }
          if (jsonError) {
            throw new Error(jsonError);
          }
        }  else {
          const resData = await response.json();
    
          // const users = resData;
          dispatch({ type: events.UPLOAD_AUDIO});
          return resData ? resData : false;
        }
      } catch (err) {
        throw new Error(err.message);
      }
    };
  }
  
//calls info

  export const getAllCalls = (id) => {
    // console.log(token)
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/files/?user_details_id=${id}`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
          }
       
      });
      if (!response.ok) {
        const resError = await response.json();
        let jsonError=""
        if(typeof(resError)==="object"){
          jsonError= JSON.stringify(resError)
        }else{
          jsonError=resError
        }
        if (jsonError) {
          throw new Error(jsonError);
        }
      }  else {
        const resData = await response.json();

        // const users = resData;
        dispatch({ type: events.GET_ALL_CALLS,payload:resData });
        return resData ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//get all follow up users
export const getFollowupUsers = (page,sort,search,status) => {
  // console.log(token)
return async (dispatch) => {
  const url=search?`${events.API_URL}/followups/?page=${page}&ordering=${sort}&search=${search}&followup_at=${moment(Date.now()).format("YYYY-MM-DD")}${status ? `&last_interaction_status=${status}`:""}`:`${events.API_URL}/followups/?page=${page}&followup_at=${moment(Date.now()).format("YYYY-MM-DD")}&ordering=${sort}${status?`&last_interaction_status=${status}`:""}`

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json' 
        }
     
    });
    if (!response.ok) {
      const resError = await response.json();
      if (resError) {
        throw new Error(resError);
      }
    } else {
      const resData = await response.json();

      // const users = resData;
      dispatch({ type: events.FOLLOW_UP_USERS,payload:resData });
      return resData ? true : false;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};
};
//make a call
export const makeCall=(id)=>{
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/user-details/${id}/initiate-call/`, {
        method: "POST",
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
          }
       
      });
      if (!response.ok) {
        const resError = await response.json();
        let jsonError=""
        if(typeof(resError)==="object"){
          jsonError= JSON.stringify(resError)
        }else{
          jsonError=resError
        }
        if (jsonError) {
          throw new Error(jsonError);
        }
      } else {
        const resData = await response.json();
  
        // const users = resData;
        dispatch({ type: events.MAKE_A_CALL, });
        return resData ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
}
//schedule a call
export const scheduleCall=(id,data,type)=>{
  
  const url=`${events.API_URL}/${type==="followup"?"followups":"user-details"}/${id}/reschedule/`
  return async (dispatch) => {
    try {
      const response = await fetch(url, {
        method:type==="followup"? "POST":"PATCH",
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
          },
          body:JSON.stringify(data)
       
      });
      if (!response.ok) {
        const resError = await response.json();
        let jsonError=""
        if(typeof(resError)==="object"){
          jsonError= JSON.stringify(resError)
        }else{
          jsonError=resError
        }
        if (jsonError) {
          throw new Error(jsonError);
        }
      }  else {
        const resData = await response.json();
  
        // const users = resData;
        dispatch({ type: events.MAKE_A_CALL, });
        return resData ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
}
