import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form';
import CloseIcon from '../../../icons/CloseIcon';
import { useDispatch, useSelector } from 'react-redux';
import {  getAllCalls, uploadAudio } from '../../../redux/actions/usersactions';
import moment from 'moment';
import "../users.css"
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import GLobalSelect from '../../../components/GlobalSelect';
import GlobalCalendar from '../../../components/calendar/GlobalCalendar';
import AudioPlayer from '../../../components/audioplayer/AudioPlayer';
import AudioIcon from '../../../icons/AudioIcon';

const CallInfo = () => {
    const [loading, setLoading] = useState(false)
    const modalRef = useRef(null);
    const [portalTarget, setPortalTarget] = useState(null);
    const fileInputRef = useRef(null);
    const [audioFile, setAudioFile] = useState("")
    const allCallsinfo = useSelector((state) => state.users.calls)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const dispatch = useDispatch();
    const { id } = useParams()
    const [formatedCalls, setFormatedCalls] = useState(null)

    const methods = useForm({ mode: "onSubmit", defaultValues: {} });
    const { control, formState: { errors }, reset } = methods;
   
    const fetchNotes = async () => {
        try {
            await dispatch(getAllCalls(id))
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchNotes()
    }, [])
    useEffect(() => {
        setPortalTarget(modalRef.current);
    }, [modalRef]);
    useEffect(() => {
        if (allCallsinfo) {

            // allNotes.results.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate));

            // Group items by date
            const groupedByDate = allCallsinfo.results.reduce((acc, item) => {
                // Extract the date part only (e.g., '2024-07-29')
                // const dateKey = new Date(item.created_at).toISOString().split('T')[0];
                const dateKey = new Date(item.uploaded_at).toISOString().split('T')[0];
                // Initialize array if dateKey does not exist in accumulatornoteId
                if (!acc[dateKey]) {
                    acc[dateKey] = [];
                }

                // Push the item into the corresponding date array
                acc[dateKey].push(item);

                return acc;
            }, {});
            setFormatedCalls(groupedByDate)
            // console.log(groupedByDate,"groupedByDate")
        }

    }, [allCallsinfo])
  
    const handleDateChange = (date) => {
        setSelectedDate(date)
    }
    const handleClick = () => {
        fileInputRef.current.click(); // Trigger click on the file input element
    };
    //add audio
    const addAudio = async (data) => {
        if (audioFile) {
            setLoading(true)
            const formData = new FormData();
            formData.append("call_type", data.call_type && data.call_type.value === "Inbound call" ? "inbound" : "outbound");
            formData.append("user_details_id", id);
            formData.append("call_date", moment(selectedDate).format("YYYY-MM-DD"));
            formData.append("file", audioFile);

            try {
                await dispatch(uploadAudio(formData))
                await dispatch(getAllCalls(id))
                document.getElementById('my_modal_7').close();
                reset();
                setAudioFile("")

            } catch (err) {
                console.log(err)
            }
            setLoading(false)
        } else {
            toast.error("Please select file")
        }
    }
    //audio upload
    const handleAudioUpload = (e) => {
        console.log(e)
        const file = e.target.files[0];
        console.log(file)
        if (file) {
            setAudioFile(file)
        }
    }
    // const handleDateInputChange=(e)=>{
    //     // console.log(e.target.value)
    //     setInputValue(e.target.value)
    //       console.log(moment(e.target.value).format("YYYY-MM-DD"))
    //       if(moment(e.target.value).format("YYYY-MM-DD")!=="Invalid date"){
    //         setSelectedDate(moment.utc(e.target.value))
    //       }
        
    //       }


  

    return (
        <>
            <div>
                <div className='flex justify-between md:pb-20-scale-924 pb-20-scale-mob'>
                    <p className="md:text-24-scale-924 text-24-scale-mob font-[600]">Calls</p>
                    <button type="button" className="md:h-40-scale-924 h-40-scale-mob md:px-10-scale-924 px-10-scale-mob border border-1 border-black-100 rounded-[6px]  text-14-scale-mob md:text-14-scale-924 bg-black-100 text-white-100" onClick={() => document.getElementById('my_modal_7').showModal()}>
                        <div className="flex items-center">Upload audio</div></button>

                </div>

                {formatedCalls && Object.keys(formatedCalls).map(val =>
                    <div key={val} className="form-fields flex flex-col  ">

                        <div className="bg-[#F6F6F6] p-10-scale-mob md:p-10-scale-924 font-[600]">
                            {moment(val).format("DD MMM YYYY")}
                        </div>
                        <div className="dashed-border-large md:pl-20-scale-924 pl-20-scale-mob">


                            {formatedCalls[val].map(call => (
                                <div key={call.id} className="pb-[50px]">
                                    <div className="p-20-scale-924 flex flex-col gap-[10px] card_shadow ">
                                        <AudioPlayer call={call} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>)}
            </div>
            {/* modal */}
            <dialog className="modal " id="my_modal_7" ref={modalRef}>
                <div className="modal-box w-[95%] md:min-w-[800px] min-h-[490px]">
                    <div className="flex justify-between border-b-[1px] border-[black] items-center pb-[15px] px-[15px]" >
                        <h3 className="md:text-24-scale-924 text-24-scale-mob font-inter ">Upload audio</h3>
                        <button onClick={() => { document.getElementById('my_modal_7').close(); reset(); setAudioFile("") }} ><CloseIcon /></button>
                    </div>
                    <div className="flex flex-col gap-y-[10px]">
                        <div className="flex justify-between pt-[20px]">
                            <div className="w-[45%] ">
                                <GLobalSelect control={control} name="call_type" label="Call Type" menuPortalTarget={portalTarget} options={[{ label: "Outbound call", value: "Outbound call" }, { label: "Inbound call", value: "Inbound call" }]}
                                    required={true}
                                    rules={{ required: "Please select call type" }} errors={errors.call_type}
                                />
                            </div>
                            <div className="w-[45%]">
                                <GlobalCalendar name="date" labelStyles={"text-20-scale-mob md:text-16-scale-924  "} className={"h-50-scale-924-mob md:!h-45-scale-924"} selectedDate={selectedDate} handleDateChange={handleDateChange} label="Call date" maxDate={new Date()}/>

                            </div>
                        </div>
                        <p>Add Audio</p>

                        {audioFile ?
                            <div className="min-h-[100px] rounded-[4px] border border-black-100 flex flex-col gap-[5px] justify-center p-20-scale-mob md:p-30-scale-924 border">
                                <p>Uploaded file</p>

                                <div className="flex gap-[10px] p-10-scale-mob md:p-10-scale-924 border items-center w-[60%] justify-between ">
                                    <div className="flex gap-[10px]">
                                        <AudioIcon />
                                        <p>{audioFile.name}</p>
                                    </div>
                                <button onClick={()=>setAudioFile("")}>
                                    <CloseIcon className="w-15-scale-924 h-15-scale-924"/>
                                </button>

                                </div>
                                </div>
                            :
                            <div className="min-h-[170px] border-dashed border border-black-100 flex flex-col gap-[5px] items-center justify-center">
                                <p className="text-14-scale-mob md:text-14-scale-924">{audioFile ? audioFile.name : ""}</p>
                                <input type="file" accept="audio/*" label="Upload Photos" style={{ display: 'none' }} ref={fileInputRef} onChange={handleAudioUpload} />
                                <button type="button" onClick={handleClick} className="bg-[black] border-1 border-color-[black] w-[150px] h-[45px] rounded-[10px] text-[white]">Browse</button>
                            </div>
                        }

                    </div>
                    <div className="flex justify-end pt-[20px]">
                        <button type="button" className="bg-[black] border-1 border-color-[black] w-[150px] h-[45px] rounded-[10px] text-[white]" onClick={methods.handleSubmit(addAudio)} disabled={loading}>{loading ? <span className="loading loading-spinner loading-sm"></span> : "Add"}</button>
                        {/* <button type="button" className="bg-[black] border-1 border-color-[black] w-[150px] h-[45px] rounded-[10px] text-[white]" onClick={methods.handleSubmit(addAudio)}>Upload audio</button> */}
                    </div>
                </div>
                <form method="dialog" className="modal-backdrop">
    <button onClick={()=>{reset();setAudioFile("")}}>close</button>
  </form>
            </dialog>
        </>
    )
}

export default CallInfo