//Api Url
// export const API_URL="http://216.48.189.172:8000"
export const API_URL="http://216.48.189.150:8000"
// export const API_URL="https://be.prod.swarnaayu.com"

export const BASIC_INFO="BASIC_INFO";
export const MEDICAL_INFO = "MEDICAL_INFO";
export const SURGICAL_INFO ="SURGICAL_INFO";
export const HOBBIES_INFO="HOBBIES_INFO";


//auth actions
export const GET_OTP="GET_OTP";
export const SEND_OTP="SEND_OTP"

//users
export const GET_ALL_USERS="GET_ALL_USERS"
export const ADD_USER="ADD_USER";
export const GET_ALL_PRIVATE_CONTACTS="GET_ALL_PRIVATE_CONTACTS"
export const ADD_USER_DETAILS="ADD_USER_DETAILS";
export const EDIT_USER_DETAILS="EDIT_USER_DETAILS";
export const GET_USER_DETAILS="GET_USER_DETAILS";
export const GET_SINGLE_USER_DETAILS="GET_SINGLE_USER_DETAILS"
export const FOLLOW_UP_USERS="FOLLOW_UP_USERS"
//call status
export const CALL_STATUS="CALL_STATUS"
export const FOLLOW_UP_CALL_STATUS="FOLLOW_UP_CALL_STATUS"
export const MAKE_A_CALL="MAKE_A_CALL"

//province
export const GET_ALL_PROVINCE="GET_ALL_PROVINCE";
export const GET_CITIES="GET_CITIES"

//notes
export const ADD_NOTES="ADD_NOTES";
export const EDIT_NOTES="EDIT_NOTES"
export const GET_ALL_NOTES="GET_ALL_NOTES"

//stats
export const STATS="STATS"

//audio upload
export const UPLOAD_AUDIO="UPLOAD_AUDIO"
export const GET_ALL_CALLS="GET_ALL_CALLS"

//Add Agents
export const ADD_AGENT="ADD_AGENT"

//Ai Insights
export const GET_AI_INSIGHTS="GET_AI_INSIGHTS"
//CHAT
export const GET_CHAT="GET_CHAT"
export const EDIT_CHAT_MODE="EDIT_CHAT_MODE"
export const SEND_FEEDBACK="SEND_FEEDBACK"
export const GET_COMPANION_MODE="GET_COMPANION_MODE"
export const EDIT_CHAT="EDIT_CAHT"
export const SEND_MSG="SEND_MSG"