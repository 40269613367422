import React from "react";

function DownArrowIcon({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
    //   width="12"
    //   height="7"
      fill="none"
      viewBox="0 0 12 7"
      className={`w-12-scale-mob md:12-scale h-7-scale-mob md:h-7-scale ${className}`}
    >
      <path
        fill="#1C1B1F"
        d="M6 6.95c-.133 0-.258-.02-.374-.062a.877.877 0 01-.325-.213l-4.6-4.6a.948.948 0 01-.275-.7c0-.283.091-.516.275-.7A.948.948 0 011.4.4c.283 0 .516.092.7.275l3.9 3.9 3.9-3.9A.948.948 0 0110.6.4c.283 0 .516.092.7.275a.948.948 0 01.275.7.948.948 0 01-.275.7l-4.6 4.6c-.1.1-.209.171-.325.213A1.107 1.107 0 016 6.95z"
      ></path>
    </svg>
  );
}

export default DownArrowIcon;