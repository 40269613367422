import React,{useEffect} from 'react'
import SideNav from '../SideNav'
// import "../users.css"
import "./aiinsights.css"
import HobbiesIcon from '../../../../icons/HobbiesIcon';
import IntrestIcon from '../../../../icons/IntrestIcon';
//redux
import { useSelector } from 'react-redux';

const Hobbies = () => {
  const ainsights = useSelector((state) => state.common.aiinsights);

 
  return (
    <div className="flex justify-between flex-col md:flex-row">
      <div className='md:w-[20%]'>
      <SideNav  aisights={true}/>
      </div>
          <div className="md:w-[77%] flex flex-col form-fields">
                      <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob flex flex-col form-gap-20">
                         
                              <div className="flex items-center gap-x-[10px]">
                                  <HobbiesIcon />
                                  <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[10px]'>Hobbies</p>
                              </div>
                              {ainsights?.hobbies_and_interests?.hobbies&&<div className="bg-white-100 border border-[#EBEBEB] rounded-[8px] shadow-sm">
                                  <p className='p-12-scale-mob md:p-10-scale-924 text-16-scale-mob md:text-16-scale-924'>
                                    {ainsights?.hobbies_and_interests?.hobbies}
                                  </p>
                              </div>}

                      </div>
                      <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob flex flex-col form-gap-20">
                          <div>
                              <div className="flex items-center gap-x-[10px] md:pb-20-scale-924 pb-20-scale-mob ">
                                  <IntrestIcon />
                                  <p className='text-16-scale-mob md:text-16-scale-924 font-[600] pt-[10px]'>Interests</p>

                              </div>
                              {ainsights?.hobbies_and_interests?.interests&&<div className="bg-white-100 border border-[#EBEBEB] rounded-[8px] shadow-sm">
                                  <p className='p-12-scale-mob md:p-10-scale-924 text-16-scale-mob md:text-16-scale-924'>
                                    {ainsights?.hobbies_and_interests?.interests}
                                  </p>
                              </div>}
                            </div>

                      </div>

         
            </div>
</div>
  )
}

export default Hobbies