import React, { useState, useEffect } from 'react'

import "./form.css"
import GlobalInput from '../../components/GlobalInput';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import FormFooter from './FormFooter';
import GLobalSelect from '../../components/GlobalSelect';
import { editUserDeatils, getUserDetails } from '../../redux/actions/usersactions';
import { formatString } from '../../methods';
import { toast } from 'react-toastify';
import { usePath } from '../../states/PathContext';

const HobbiesInfo = () => {
    const {path}=usePath()
    //button loader
    const [loading, setLoading] = useState(false)
    const formData = useSelector((state) => state.users.user_details);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const params = useParams();
    const { id } = params;
    const methods = useForm({ mode: "all" });
    const [state, setState] = useState({})

    const { control, formState: { errors, dirtyFields }, setValue, getValues } = methods;
    const cheboxlabels = ["Arts & Craft", "Music", "Religious & Spiritual Activities", "Cooking", "Gardening", "Watching Tv", "Puzzles & Board Games", "Reading", "Others"]
    const dietLabels = ["Less Carbs", "Less Sugar", "Less Fat", "Less Oil", "Less Salt", "None"];
    const mealLabels = ["Veg", "Non Veg", "Vegan", "Veg + Egg","Jain Food"];
    const cuisineLabels = ["Indian", "Chinese", "Japanese", "American", "Others"];
    const onSubmit = async (data) => {
        // if (state.hobbies && state.hobbies.length > 0 && state.diet_restriction && state.diet_restriction.length > 0 && state.meal_preference) {
            setLoading(true)
            const reqData = formattedData()
            try {
                await dispatch(editUserDeatils(id, { ...reqData, social_events_and_activity: data.social_events_activities && data.social_events_activities.value.toLowerCase() ,allergy:data.allergy}));
                await dispatch(getUserDetails(id))
                // navigate(`/user/add/${id}/summary`,)
                  navigate(`/user/add/${id}/medicalinfo`, { state: { from: path } })
            } catch (err) {
                console.log(err)
                toast.error(err.message)
            }
            setLoading(false)
        // } else {
        //     toast.error("Please select hobbies,diet restrictions and meal preference")
        // }

    }

    const handleCheckboxChange = (event, name) => {
        const { value } = event.target;

        const findValue = formData?.[name]?.find(val => {
            if (name === "hobbies") {
                return val.hobby === value.toLowerCase().replace(/ /g, "_")
            } else {
                return val.restriction === value.toLowerCase().replace(/ /g, "_")
            }
        });

        const checked = { name: value };
        if (findValue) {
            checked.id = findValue.id
        }
        if (event.target.type === "checkbox") {
            setState({
                ...state, [name]: state[name] ? state[name].some(obj => obj.name === value) ? state[name].filter(val => val.name !== value) : [...state[name], checked] : [checked]
            })
            //    setState({...state,[name]:state[name]?state[name].includes(value)?state[name].filter(val=>val!==value):[...state[name],value]:[value]
            // })
        }
    };
    const handleChangeRadioButton = (event, name) => {
        const { value } = event.target;
        if (event.target.type === "radio") {

            setState({ ...state, [name]: value })
        }
    }
    useEffect(() => {
        if (formData && formData.id === parseInt(id)) {
            const { hobbies, diet_restrictions, meal_preferences, cuisine_preferences, exercise_details, sleep_pattern,...restFormData } = formData;

            const hobbiesData = hobbies ? hobbies.length > 0 && hobbies.map(val => {
                if (val.hobby === "others") {
                    setValue("other_hobbies", val.other_hobbies)
                } else {
                    setValue("social_events_and_activity", { label: val.social_events_and_activity, value: val.social_events_and_activity })
                }
                return { name: val.hobby&&formatString(val.hobby), id: val.id }
            }) : [];
            const diet = diet_restrictions ? diet_restrictions.length > 0 && diet_restrictions.map(val => ({ name: val.restriction&&formatString(val.restriction), id: val.id })) : [];
            const meal_preference = meal_preferences ? meal_preferences.preference==="veg_egg"?"Veg + Egg":meal_preferences.preference&&formatString(meal_preferences.preference) : ""
            const cuisine_preference = cuisine_preferences ? formatString(cuisine_preferences.cuisine) : "";
            if (exercise_details) {
                const exerciseTypes = exercise_details.type_of_exercise&&exercise_details.type_of_exercise.map(val => ({ label: formatString(val), value: formatString(val) }))
                setValue("exercise_frequency", { label: formatString(exercise_details.frequency), value: formatString(exercise_details.frequency) })

                setValue("exercise_type", exerciseTypes)
            }
            if (sleep_pattern) {
                setValue("sleep_pattern", { label: formatString(sleep_pattern.pattern), value: formatString(sleep_pattern.pattern) });
                setValue("sleep_duration", { label: formatString(sleep_pattern.duration), value: formatString(sleep_pattern.duration) })

            }
            if(restFormData.elergy){
                setValue("allergy",restFormData.elergy)
            }
            setState({ ...state, diet_restriction: diet, hobbies: hobbiesData, meal_preference, cuisine_preference })

        }

    }, [formData])
    const onCloseForm = async () => {
        const reqData = formattedData();

        try {
            await dispatch(editUserDeatils(id, reqData));
            await dispatch(getUserDetails(id))
            path==="/users"? navigate(`/users`):navigate("/usermanagement")
        } catch (err) {
            console.log(err);
            toast.error(err.message)
        }
    }
    const handleBackClick = async () => {
        const reqData = formattedData();
        try {
            await dispatch(editUserDeatils(id, reqData));
            await dispatch(getUserDetails(id))
            navigate(-1)
        } catch (err) {
            console.log(err);
            toast.error(err.message)
        }
    }
    //formatData for backend
    const formattedData = () => {  
        const hobbies = state.hobbies ? state.hobbies.map(val => {
            const other_hobbies = val.name === "Others" ? getValues("other_hobbies") : null;
            const result = {
                hobby: val.name.toLowerCase().replace(/ /g, "_").replace(/\&/g, "and"),
            };
            if (other_hobbies != null) {
                result.other_hobbies = other_hobbies;
            } else {
                result["social_events_and_activity"] = getValues('social_events_and_activity') && getValues("social_events_and_activity").value
            }
            if (val.id) {
                result.id = val.id
            }
            return result;

        }):[]
        const diet_restrictions = state.diet_restriction ? state.diet_restriction.map(val => {
            const result = {
                restriction: val.name.toLowerCase().replace(/ /g, "_")
            }
            if (val.id) {
                result.id = val.id
            }
            return result;
        }):[]
      
        const
            meal_preferences = { preference: state.meal_preference &&state.meal_preference==="Veg + Egg"?"veg_egg":state.meal_preference.toLowerCase().replace(/ /g, "_") };
        const cuisine_preferences = state.cuisine_preference ? { cuisine: state.cuisine_preference && state.cuisine_preference.toLowerCase() } : "";
        const exercise_details = {}
        if (dirtyFields["exercise_frequency"]) {
            exercise_details.frequency = getValues("exercise_frequency") && getValues("exercise_frequency").value.toLowerCase()
        }
        if (dirtyFields["exercise_type"]) {          
            exercise_details.type_of_exercise = getValues("exercise_type") && getValues("exercise_type").map(val => val.value.toLowerCase())
        }

        const sleep_pattern = {}
        if (dirtyFields["sleep_pattern"]) {
            sleep_pattern.pattern = getValues("sleep_pattern") && getValues("sleep_pattern").value.toLowerCase()
        }
        if (dirtyFields["sleep_duration"]) {
            sleep_pattern.duration = getValues("sleep_duration") && getValues("sleep_duration").value.toLowerCase()
        }

       
        const reqData = {
            hobbies,
            diet_restrictions,

            // exercise_details,
            // sleep_pattern
        }
        if (dirtyFields["exercise_frequency"] || dirtyFields["exercise_type"]) {
            reqData.exercise_details = exercise_details
        }
        if (dirtyFields["sleep_pattern"] || dirtyFields["sleep_duration"]) {
            reqData.sleep_pattern = sleep_pattern
        }
        if (state.cuisine_preference) {
            
            reqData.cuisine_preferences = cuisine_preferences
        }
        if (state.meal_preference) {
            reqData.meal_preferences = meal_preferences
        }
        if (dirtyFields["allergy"]) {
            reqData.elergy= getValues("allergy") 
            }
        return reqData;
    }

    return (
        <>
            <div className="px-19-scale-mob md:px-19-scale-924 pt-50-scale-mob md:pt-40-scale-924 w-[100%]">

                <div className="px-20-scale-mob md:px-41-scale-924 w-[100%] card pt-20-scale-mob md:pt-20-scale-924 pb-30-scale-mob md:pb-30-scale-924 flex flex-col md:w-[80%]  mx-auto ">
                    <p className="text-20-scale-mob md:text-20-scale-924 font-[600] ">Hobbies?</p>
                    <div className="flex flex-wrap form-fields checkbox-group" onChange={(e) => handleCheckboxChange(e, "hobbies")}>
                        {cheboxlabels.map(val =>
                            <div className='flex gap-[10px] w-[100%] md:w-[30%] items-center' key={val.name}>
                                <input type="checkbox" name={val} value={val} className="w-[30px] h-[30px] custom-checkbox" checked={state.hobbies && state.hobbies.some(value => value.name === val)} />
                                <label for="medical-info" className="text-14-scale-mob md:text-14-scale-924 " >{val}</label>
                            </div>
                        )}

                    </div>
                    {/* <GlobalInput control={control} name="other_hobbies" label="Other Hobbies" required={true}/> */}
                    {state.hobbies && state.hobbies.some(val => val.name === "Others") && <div className="w-[100%] md:Fw-[75%]">
                        <GlobalInput name="other_hobbies" label="Other hobbies" control={control} 
                        labelStyles={"text-16-scale-mob md:text-16-scale-924 font-[600]"}   />
                    </div>}
                    <div className="w-[100%] md:w-[40%]">
                        <GLobalSelect control={control} name="social_events_and_activity" label="Social events & activity ?" options={[{ label: "Regularly", value: "Regularly" }, { label: "Weekly Once", value: "Weekly Once" }, { label: "Monthly Once", value: "Monthly Once" }, { label: "Occasionally", value: "Occasionally" }, { label: "Rarely", value: "Rarely" }]} labelStyles={"text-20-scale-mob md:text-16-scale-924 font-[600]"} />
                    </div>
                    <hr className={"bg-[black] text-[black] h-[3px] my-[20px]"} />
                    <p className="text-20-scale-mob md:text-20-scale-924 font-[600] ">Diet Restrictions ?</p>
                    <div className="flex flex-wrap form-fields checkbox-group" onChange={(e) => handleCheckboxChange(e, "diet_restriction")}>
                        {dietLabels.map(val =>
                            <div className='flex gap-[10px]  w-[100%] md:w-[30%]  items-center '>
                                <input type="checkbox" name={val} value={val} className="w-[30px] h-[30px] custom-checkbox" checked={state.diet_restriction && state.diet_restriction.some(value => value.name === val)} />
                                <label for="medical-info" className="text-14-scale-mob md:text-14-scale-924" >{val !== "Others" ? `No/ ${val}` : { val }}</label>
                            </div>
                        )}

                    </div>
                    <GlobalInput name="allergy" label="Allergy" control={control} labelStyles={"text-20-scale-mob md:text-16-scale-924 font-[600]"} className={"h-50-scale-924-mob md:!h-45-scale-924"}
                    // required={true} rules={{ required: "Allergy Field is required" }} errors={errors.allergy}
                      />

                    {/* <GlobalInput name="allergy" label="Allergy" control={control} labelStyles={"text-16-scale-mob md:text-16-scale-924 font-[600]"}  required={true} rules={{ required: "other hobbies are required" }} errors={errors.other_hobbies} /> */}
                    <p className="text-20-scale-mob md:text-20-scale-924 font-[600]">Meal preference ?</p>
                    <div className="flex flex-wrap form-fields radio-group " onChange={(e) => handleChangeRadioButton(e, "meal_preference")}>
                        {mealLabels.map(val =>
                            <div className='flex gap-[10px] w-[45%] md:w-[20%] items-center'>
                                <input type="radio" value={val} className="w-[30px] h-[30px] custom-radio" name="meal_preference" checked={state.meal_preference && state.meal_preference === val} />
                                <label for="medical-info" className="text-14-scale-mob md:text-14-scale-924 " >{val}</label>
                            </div>
                        )}

                   
                    </div>
                    <p className="text-20-scale-mob md:text-20-scale-924 font-[600] ">Cuisine  preference ?</p>
                    <div className="flex flex-wrap form-fields radio-group" onChange={(e) => handleChangeRadioButton(e, "cuisine_preference")}>
                        {cuisineLabels.map(val =>
                            <div className='flex gap-[10px] w-[45%] md:w-[20%] items-center '>
                                <input type="radio" value={val} className="w-[30px] h-[30px] custom-radio " name="cuisine_preference" checked={state.cuisine_preference && state.cuisine_preference === val} />
                                <label for="medical-info" className="text-14-scale-mob md:text-14-scale-924 " >{val}</label>
                            </div>
                        )}

                    </div>
                    <div className="flex flex-wrap justify-between form-fields mt-51-scale-mob md:mt-50-scale-924">
                        <div className="w-[100%] md:w-[45%]">
                            <GLobalSelect label="How often do you exercise ?" control={control} name="exercise_frequency" options={[{ label: "Everyday", value: "Everyday" }, { label: "3-5 times a week", value: "3-5 times a week" }, { label: "Occasionally", value: "Occasionally" }, { label: "Never", value: "Never" }]} labelStyles={"text-20-scale-mob md:text-16-scale-924 font-[600]"} 
                             />
                        </div>
                        <div className="w-[100%] md:w-[45%]">
                            <GLobalSelect label="Type of exercise ?" isMulti={true} control={control} name="exercise_type" options={[{ label: "Walking", value: "Walking" }, { label: "Jogging/Running", value: "Jogging/Running" }, { label: "Swimming", value: "Swimming" }, { label: "Cycling", value: "Cycling" }, { label: "Sports", value: "Sports" }, { label: "Yoga", value: "Yoga" }, { label: "Other", value: "Other" }]} labelStyles={"text-20-scale-mob md:text-16-scale-924 font-[600]"} 
                            />
                        </div>
                        <div className="w-[100%] md:w-[45%]">
                            <GLobalSelect label="Sleep pattern ?" control={control} name="sleep_pattern" options={[{ label: "Deep sleep", value: "Deep sleep" }, { label: "Interrupeted sleep", value: "Interrupeted sleep" }, { label: 'Insomnia', value: "Insomnia" }]} labelStyles={"text-20-scale-mob md:text-16-scale-924 font-[600]"} 
                           />
                        </div>
                        <div className="w-[100%] md:w-[45%]">
                            <GLobalSelect label="Sleep duration ?" control={control} name="sleep_duration" options={[{ label: "Less than 6 hours", value: "Less than 6 hours" }, { label: "6-8 hours", value: "6-8 hours" }, { label: "8-10 hours", value: "8-10 hours" }, { label: "Greater than 10 hours", value: "Greater than 10 hours" }]} labelStyles={"text-20-scale-mob md:text-16-scale-924 font-[600]"} 
                            />
                        </div>
                    </div>
                </div>

            </div>
            <div className="pt-150-scale-mob md:pt-100-scale-924">
                <FormFooter onNextClick={methods.handleSubmit(onSubmit)} back={true} loading={loading} onClose={onCloseForm} onBack={handleBackClick} />
            </div>
        </>
    )
}

export default HobbiesInfo