import React, { useRef, useEffect, useState, useCallback } from 'react'
import "./users.css"
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
//componets
import GlobalTextArea from '../../components/GlobalTextArea';
import GlobalCalendar from '../../components/calendar/GlobalCalendar';
import Pagination from '../../components/pagination/Pagination';
import GLobalSelect from '../../components/GlobalSelect';
import GlobalTable from '../../components/Table/GlobalTable';
import NavBar from '../../components/navbar/NavBar';
import GlobalDatePicker from '../../components/dateTimepicker/GlobalDatePicker';
//redux
import { useDispatch, useSelector } from "react-redux"
import { addNotes, addUser, getAllPrivateContacts, getAllUsers, getFollowupUsers, getStats, makeCall, scheduleCall } from '../../redux/actions/usersactions';
//icons
import AddNoteIcon from '../../icons/AddNoteIcon';
import MakeCallIcon from '../../icons/MakeCallIcon';
import CallIcon from '../../icons/CallIcon';
import AddIcon from '../../icons/AddIcon';
import EyeIcon from '../../icons/EyeIcon';
import EditIcon from '../../icons/EditIcon';
import GlobalInput from '../../components/GlobalInput';
import CloseIcon from '../../icons/CloseIcon';
import SearchIcon from '../../icons/SearchIcon';
import { compareTime, formatString } from '../../methods';

const Users = () => {
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement
  );
  const location = useLocation();
  //search
  const [search, setSearch] = useState("")
  const [followUpSearch, setFollowUpSearch] = useState("");
  //filters
  const [statusFilter, setStatusFilter] = useState()
  const [lastInteraction,setLastInteraction]=useState()
  //sort
  const [sort, setSort] = useState("");
  const [followUpSort, setFollowUpSort] = useState("")
  //reschdule date
  const [selectedDate, setSelectedDate] = useState(null)
  const [userId, setUserId] = useState("")
  const [dataType, setDataType] = useState(localStorage.getItem("userType") || "new")
  const token = localStorage.getItem("token")
  const [addLoading, setAddLoading] = useState(false)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState(new Date());
  const [userType, setUserType] = useState("new")
  const [loading, setLoading] = useState(true);
  const [notesLoading, setNotesLoading] = useState(false)
  const [statsLoading, setStatsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const users = useSelector((state) => state.users.users);
  const followupusers = useSelector((state) => state.users.followupUsers)
  const contacts = useSelector((state) => state.users.contacts)
  const stats = useSelector((state) => state.users.stats)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [portalTarget, setPortalTarget] = useState(null);
  const modalRef = useRef(null);
  const methods = useForm({
    mode: "all",
    defaultValues: { user_type: { label: "New Users", value: "new" } }
  }
  );
  const methods1 = useForm({
    mode: "all"

  }
  );
  const { control, formState: { errors }, reset, getValues } = methods;
  const { control: control1, formState: { errors: errors1 }, reset: reset1 } = methods1;

  const headings = [{ name: "Date of adding", value: "date_of_adding", sort: true, render: (val) => <p>{moment.utc(val.created_at).format('MMMM DD, YYYY')}</p> },
  { name: "Name", value: "full_name" }, { name: "Agent assigned", value: "agent_name" },
  { name: "Age", value: "age" },
  { name: "Last call", value: "last_call_date", render: (val) => <p>{val.last_call_date ? moment.utc(val.last_call_date).format('MMMM DD, YYYY') : "-"}</p> },
  { name: "Duration", value: "call_duration" },
   { name: "Call status", render: (val) => val.call_status?<div className='bg-[#C4C4C4] p-[5px] rounded-[6px] max-w-[100px] self-center text-center'>{formatString(val.call_status)}</div>:"-" },
  { name: "Form status", render: (val) => <div className='bg-[#C4C4C4] p-[5px] rounded-[6px] max-w-[100px] self-center text-center'>{formatString(val.form_status)}</div> },
  { name: "Phone number", value: "phone_number", render: (val) => <p>{val.phone_number && val.phone_number.replace(/^91/, '')}</p> }
    , {
    name: "Actions", headingStyles: "text-center", dataStyles: "align-center text-center self-center", render: (val) => <div className="flex gap-x-[10px] items-center justify-center" >
      {val.form_status === "completed" ?
        <>
          <button onClick={() => navigate(`/user/${val.id}`)}><EyeIcon /></button>
        </> :
        val.form_status === "in progress" ?
          <>
            <div className="tooltip" data-tip="Schedule call">
              <GlobalDatePicker className="w-20 h-20" date={selectedDate && selectedDate[val.id] ? selectedDate[val.id] : val.rescheduled_date ? moment(val.rescheduled_date).local().toDate() : new Date()} handleDateChange={(value) => handleDateChange(value, val.id)} handleCalendarClose={() => handleCalendarClose(val.id,"new")} />
            </div>
            <div className="tooltip" data-tip="Make a call">
              <button onClick={() => handleMakeCall(val.id)}><MakeCallIcon /> </button>
            </div>
            {/* <div className="tooltip" data-tip="Update call status">
            <button onClick={() => handleUpdateCallStatus(val.id)} disabled={val.call_status === "missed" ? true : false}><CallIcon missed={val.call_status === "missed" ? true : false} isMarkedRed={val.is_marked_red}/> </button>
            </div> */}
            <div className="tooltip" data-tip="Edit form">

              <button onClick={() => navigate(`/user/add/${val.id}`, { state: { from: location.pathname } })}><EditIcon /></button>
            </div>
          </> :
          <>
            <div className="tooltip" data-tip="Schedule call">
              <GlobalDatePicker className="w-20 h-20" date={selectedDate && selectedDate[val.id] ? selectedDate[val.id] : val.rescheduled_date ? moment(val.rescheduled_date).local().toDate() : new Date()} handleDateChange={(value) => handleDateChange(value, val.id)} handleCalendarClose={() => handleCalendarClose(val.id,"new")} />
            </div>
            <div className="tooltip" data-tip="Make a call">
              <button onClick={() => handleMakeCall(val.id)}><MakeCallIcon /> </button>
            </div>
            {/* <div className="tooltip" data-tip="Update call status">
            <button onClick={() => handleUpdateCallStatus(val.id)} disabled={val.call_status === "missed" ? true : false}><CallIcon missed={val.call_status === "missed" ? true : false}  isMarkedRed={val.is_marked_red}/> </button>
            </div> */}
            <div className="tooltip" data-tip="Add Details">
              <button onClick={() =>
                //  navigate(`/user/add/${val.id}`)}
                navigate(`/user/add/${val.id}`, { state: { from: location.pathname } })
              }
              ><AddIcon /></button>
            </div>

          </>

      }
    </div>
  }]
  //followup table
  const headingsFollowup = [{ name: "Date registered", value: "date_of_adding", sort: true,render: (val) => <p>{moment.utc(val.created_at).format('MMMM DD, YYYY')}</p> },
  { name: "Name", value: "full_name" }, { name: "Agent assigned", value: "agent_name" },
  { name: "Age", value: "age" },

  { name: "Last call", value: "last_call_date", render: (val) => <p>{val.last_call_date ? moment.utc(val.last_call_date).format('MMMM DD, YYYY') : "-"}</p> },
  { name: "Last interaction", value: "last_interaction", render: (val) => <p className={`${val.last_interaction_date&&compareTime(val.last_interaction_date)==="Greater than 48 hours"?"text-[red]":"text-[black]"} `}>{val.last_interaction_date ? compareTime(val.last_interaction_date) : "-"}</p>},
  // { name: "Status", value: "form_status" },
  { name: "Call Status", render: (val) => <div className='bg-[#C4C4C4] p-[5px] rounded-[6px] max-w-[100px] self-center text-center'>{val.call_status && formatString(val.call_status)}</div> },

  { name: "Phone number", value: "phone_number", render: (val) => <p>{val.phone_number && val.phone_number.replace(/^91/, '')}</p> },

  {
    name: "Action", headingStyles: "text-center", dataStyles: "align-center text-center self-center", render: (val) => <div className="flex gap-x-[10px] items-center justify-center" >

      <>
        <div className="tooltip" data-tip="Schedule call">
          <GlobalDatePicker className="w-20 h-20" date={selectedDate && selectedDate[val?.id] ? selectedDate[val?.id] : val.followup_at ? moment(val.followup_at).local().toDate() : new Date()} handleDateChange={(value) => handleDateChange(value, val?.id)} handleCalendarClose={() => handleCalendarClose(val?.id,"followup")} />
        </div>
        <div className="tooltip" data-tip="Make A call">
          <button onClick={() => handleMakeCall(val.user_details?.id)}><MakeCallIcon /> </button>
        </div>
        {/* <div className="tooltip" data-tip="Update call status">
            <button onClick={() => handleFollowupUpdateCallStatus(val.id)} disabled={val.status === "missed" ? true : false}><CallIcon missed={val.status === "missed" ? true : false} /> </button>
          </div> */}
        <div className="tooltip" data-tip="View details">
          <button onClick={() => navigate(`/user/${val.user_details?.id}`)}><EyeIcon /></button>
        </div>
        <div className="tooltip" data-tip="Add note">
          <button onClick={() => {
            setUserId(val.user_details?.id);
             document.getElementById('my_modal_8').showModal();
          }}><AddNoteIcon /></button>
        </div>
      </>
    </div>
  }]
  if (!token) {
    navigate("/")
  }
  useEffect(() => {
    setPortalTarget(modalRef.current);
  }, [modalRef]);
  //add users
  const onSubmit = async (data) => {
    // const id=localStorage.getItem("id")
    try {
      setAddLoading(true)
      await dispatch(addUser({ agent: data.assigned_to && data.assigned_to.value && data.assigned_to.value, full_name: data.full_name, age: data.age && parseInt(data.age), gender: data.gender && data.gender.value, phone_number: `91${data.phone_number}` }))
      await dispatch(getAllPrivateContacts(1, "new", sort, search))
      setCurrentPage(1)
      fetchStats()
      toast.success("User created Successfully")
      reset()
      document.getElementById('my_modal_7').close();

    } catch (err) {
      console.log(err);
      toast.error(err.message)
    }
    setAddLoading(false)

  }
  //fetchContacts
  const fetchContacts = async () => {
    try {
      setLoading(true)
      await dispatch(getAllPrivateContacts(currentPage, "new", sort, search, getValues("status") ? getValues("status").value : undefined))
      await dispatch(getFollowupUsers(currentPage, followUpSort, followUpSearch,getValues("last_interaction") ? getValues("last_interaction").value : undefined))
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }
  //fetchUser
  const fetchUsers = async () => {
    try {

      await dispatch(getAllUsers());

    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (token) {
      fetchContacts()
    }
  }, [currentPage, token, search, sort, followUpSort, followUpSearch, statusFilter,lastInteraction])
  useEffect(() => {
    fetchUsers()
  }, [])
  const options = users && users.results && users.results.map(val => ({ value: val.id, label: val.username }))
  //calendar
  const handleEndDateChange = (date) => {
    setEndDate(date);

  };
  const handleStartDateChange = (date) => {
    setStartDate(date);

  }
  const fetchStats = async () => {
    setStatsLoading(true)
    try {
      await dispatch(getStats(startDate ? moment(startDate).format("YYYY-MM-DD") : "", moment(endDate).format("YYYY-MM-DD"), userType))
    } catch (err) {
      console.log(err)
    }
    setStatsLoading(false)
  }
  useEffect(() => {
    fetchStats()
  }, [userType, startDate, endDate])
  const backgroundColor = ["#D9D9D9", "#242222", "#B7A6A6", "#8F8F8F"];
  const data = {
    labels: userType === "followup" ? ["Calls Completed", "Calls Pending", "Calls Missed"] : ["Calls Completed", "Calls Pending", "Calls Missed", "Calls Incomplete"],
    datasets: [
      {
        label: "Users",
        // data: leadsmeter ? [leadsmeter.plan_leads, leadsmeter.sent_leads] : [],
        data:
          stats ? userType === "followup" ? [stats.followups_completed, stats.followups_pending, stats.followups_missed] : [stats.calls_completed, stats.calls_pending, stats.calls_missed, stats.calls_incomplete] : [],

        backgroundColor: ["#D9D9D9", "#242222", "#B7A6A6", "#8F8F8F"],
        borderWidth: 0,
        // needleValue: 10,
      },
    ],
  };
  const chatoptions = {
    plugins: {
      legend: {
        display: false,
        position: "bottom",


      }
    }
  }

  const textPlugins = {
    afterDraw: (chart) => {
      const ctx = chart.ctx;
      const canvasCenterX = chart.width / 2;
      const canvasCenterY = chart.height / 2.2;
      const line1 =
        "Total users";
      const line2 = stats ? userType === "followup" ? stats.total_followups : stats.total_users : 0;

      // Font settings
      ctx.font = "20px Arial";
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      // Draw text at the center
      // ctx.fillText(line1, canvasCenterX, canvasCenterY - 10); // Adjust vertical position
      ctx.fillText(line1, canvasCenterX, canvasCenterY + 10); // Adjust vertical position
      ctx.fillText(line2, canvasCenterX, canvasCenterY + 40);
    },
  };
  //updateCallstatus
  // const handleUpdateCallStatus = async (id) => {
  //   try {
  //     await dispatch(updateCallStatus(id));
  //     // await dispatch
  //     // await dispatch(getAllPrivateContacts(currentPage, "new"));
  //     await dispatch(getFollowupUsers(1))
  //     userType === "new" && fetchStats()
  //     toast.success("call status updated successfully")
  //   } catch (err) {
  //     console.log(err.message);
  //     toast.error("An error occured")
  //   }

  // }
  //make a call
  const handleMakeCall = async (id) => {
    try {
      await dispatch(makeCall(id))      
      toast.success("Call Initiated Successfully")
    } catch (err) {
      console.log(err.message);
      toast.error(err.message)
    }
  }
  //update followup call status

  // const handleFollowupUpdateCallStatus = async (id) => {
  //   try {
  //     await dispatch(updateFollowupCallStatus(id));
  //     // await dispatch(getAllPrivateContacts(currentPage, "new"));
  //     // await dispatch(getFollowupUsers(currentPage))
  //     userType === "followup" && fetchStats()
  //     toast.success("call status updated successfully")
  //   } catch (err) {
  //     console.log(err.message);
  //     toast.error("An error occured")
  //   }

  // }
  const submitNotes = async (data) => {
    setNotesLoading(true)

    try {
      await dispatch(addNotes(userId, { note: data.note, tag: "call" }));
      // await dispatch(getFollowupUsers(currentPage))
      userType === "followup" && fetchStats()
      document.getElementById('my_modal_8').close();

      reset1()
      setUserId("")
      toast.success("Note added successfully")

      // navigate(`/user/add/${id}/surgicalinfo/${details_id}`)
    } catch (err) {
      console.log(err)
      toast.error(err.message)
    }
    setNotesLoading(false)
  }
  //search
  const handleSearch = (e) => {
    if (e.code === "Enter") {
      setSearch(getValues("search"))
    }
  }
  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(getValues("search"));
      setCurrentPage(1)
    }, 500),
    []
  );
  const handleSort = (order) => {
    // setSort(sort==="-updated_at"?"updated_at":"-updated_at")
    if (order === -1) {
      setSort("-created_at")
    } else {
      setSort("created_at")
    }
  }
  //schedule call
  const handleDateChange = async (value, id) => {
    setSelectedDate({ [id]: value })
  }
  const handleCalendarClose = async (id,type) => {
    if (selectedDate) {

      const utcDateTime = moment(selectedDate[id]).utc().format();
      try {
        await dispatch(scheduleCall(id, {[type==="followup"?"followup_at":"rescheduled_date"]: utcDateTime },type))
        if (localStorage.getItem("userType") === "followup") {
          await dispatch(getFollowupUsers(currentPage, followUpSort, followUpSearch))
        } else {
          await dispatch(getAllPrivateContacts(currentPage, "new", sort, search))
        }

        toast.success("Reschdule done successfully")
      } catch (err) {
        console.log(err.message);
        toast.error(err.message)
      }
      setSelectedDate(null)
    }
  }
  //followup search
  //search
  const handleFollowUpSearch = (e) => {
    if (e.code === "Enter") {
      setFollowUpSearch(getValues("followup_search"))
    }
  }
  const debouncedFollowUpSearch = useCallback(
    debounce((query) => {
      setFollowUpSearch(getValues("followup_search"));
      setCurrentPage(1)
    }, 500),
    []
  );
  const handleFollowupSort = (order) => {
    // setSort(sort==="-updated_at"?"updated_at":"-updated_at")
    if (order === -1) {
      setFollowUpSort("-created_at");
      setCurrentPage(1)
    } else {
      setFollowUpSort("created_at");
      setCurrentPage(1)
    }
  }
  const handleStatusFilter=(value)=>{
    if(value){
      setStatusFilter(value.value);
      setCurrentPage(1)
    }else{
      setStatusFilter("")
      setCurrentPage(1)
    }
  }
  const handleLastInteractionFilter=(value)=>{
    if(value){
      setLastInteraction(value.value);
      setCurrentPage(1)
    }else{
      setLastInteraction("")
      setCurrentPage(1)
    }
  }

  return (
    <>

      <NavBar />
      <div className="flex flex-col md:flex-row pt-91-scale-mob md:pt-91-scale justify-between px-50-scale">
        <div className="w-[100%] md:w-[70%]">
          <h1 className="text-24-scale-mob md:text-24-scale font-inter font-[600]">Hi {localStorage.getItem("name") ? localStorage.getItem("name").replace(/\b\w/g, char => char.toUpperCase()) : ''}, Welcome back</h1>
          <div className="flex justify-between items-center pb-[10px]">
            <h1 className='pt-[15px] text-[24px] font-inter font-[600]'>Call schedule </h1>
            {localStorage.getItem("is_staff") === "true" && <label htmlFor="my_modal_7" className="hidden md:flex  bg-[black] text-[white] w-[138px] h-[43px] rounded-[4px] justify-center items-center" onClick={() => document.getElementById('my_modal_7').showModal()}>Add New User</label>}

          </div>

          <div className="flex justify-between flex-col md:flex-row ">
            {localStorage.getItem("is_staff") === "true" && <label htmlFor="my_modal_7" className="md:hidden flex bg-[black] text-[white] w-[138px] h-[43px] rounded-[4px] self-end justify-center items-center" onClick={() => document.getElementById('my_modal_7').showModal()}>Add New User</label>}

            <div className="flex gap-[20px]">
              <button className={`py-[15px] text-16-scale-mob md:text-16-scale  ${dataType === "new" ? "border-[black] border-b-[2px] font-[600]" : ""}`} onClick={() => { setDataType("new"); setCurrentPage(1); localStorage.setItem("userType", "new") }}>New Users ({contacts ? contacts.count > 10 ? contacts.count : `0${contacts.count}` : "00"})</button>
              <button className={`py-[15px] text-16-scale-mob md:text-16-scale ${dataType === "followup" ? "border-[black] border-b-[2px] font-[600]" : ""}`} onClick={() => { setDataType("followup"); setCurrentPage(1); localStorage.setItem("userType", "followup") }}>Follow up for today ({followupusers ? followupusers.count >= 10 ? followupusers.count : `0${followupusers.count}` : "00"})</button>
            </div>
            <div className="flex gap-[15px] items-top flex-col md:flex-row flex-wrap pb-[10px] md:pb-[0px]">
              {localStorage.getItem("userType") !== "followup" && <div className="relative py-[0] h-[45px] ">
                <div className="absolute inset-y-0 left-2 flex items-center z-[20] top-0 "> <SearchIcon /></div>
                <GlobalInput control={control} name="search" search={true} className="border-[#E4E4E7] text-black-100 md:!w-[400px] " placeholder="Search by user name or ID here"
                  onKeyPress={(e) => handleSearch(e)} onChange={debouncedSearch} />
              </div>}
              {localStorage.getItem("userType") === "followup" && <div className="relative py-[0] h-[45px]">
                <div className="absolute inset-y-0 left-2 flex items-center z-[20] top-0 "> <SearchIcon /></div>
                <GlobalInput control={control} name="followup_search" search={true} className="border-[#E4E4E7] text-black-100 md:!w-[400px] " placeholder="Search by user name or ID here"
                  onKeyPress={(e) => handleFollowUpSearch(e)} onChange={debouncedFollowUpSearch} />
              </div>}
              {/* <button className="bg-[black] text-[white] w-[138px] h-[43px] rounded-[4px]" htmlFor="my_modal_7">Add New User</button> */}

              {localStorage.getItem("userType") !== "followup" && <GLobalSelect
                isClearable={true}

                controlStyles={{ minWidth: "150px" }}
                minHeight="45px"
                borderColor="#E4E4E7"
                placeholder="Status"

                onChange={(value) => handleStatusFilter(value)}
                control={control} name='status' options={[{ label: "In Progress", value: "in progress" }, { label: "New", value: "new" }]} />
              }
              {localStorage.getItem("userType") === "followup" && <GLobalSelect
               isClearable={true}
                controlStyles={{ minWidth: "200px" }}
                minHeight="45px"
                borderColor="#E4E4E7"
                placeholder="Last Interaction"
                onChange={(value) => handleLastInteractionFilter(value)}
                control={control} name='last_interaction' options={[{ label: "Less than 48 hours", value: "less_than_48_hours" }, { label: "Greater than 48 hours", value: "greater_than_48_hours" }]} />
              }
            </div>
          </div>
          <div className="custom-box-shadow rounded-[10px] pl-[15px] w-[100%]">
            <GlobalTable
              isSticky={true}
              headings={dataType === "new" ? headings : headingsFollowup}
              // data={contacts?.results }
              data={dataType === "new" ? contacts ? contacts.results : [] : followupusers ? followupusers.results.map(val => ({ ...val.user_details, ...val })) : []}
              loading={loading}
              handleSort={dataType === "new" ? handleSort : handleFollowupSort}
              sort={dataType === "new" ? sort : followUpSort}
            />
          </div>

          {!loading && <div className="py-20-scale-mob md:py-20-scale-924">
            <Pagination
              totalCount={
                dataType === "new" ? contacts ? contacts.count : 0 : followupusers ? followupusers.count : 0
              }
              currentPage={currentPage}
              pageSize={10}
              onPageChange={((currentPage) => {
                setCurrentPage(currentPage)
              })}

            />
          </div>}
        </div>


        {/* stats */}
        <div className="w-[100%] md:w-[25%]  border  border-[#C4C4C4] min-h-[881px] p-[10px] rounded-[13px]">
          <div className="bg-gray-100 w-[100%] rounded-[10px]  flex items-center py-10-scale-mob md:px-10-scale">
            <div className="flex px-20-scale justify-between w-[100%]">
              <div className="border-r-[1px] border-black-100 w-[50%]">
                <div className="w-[90%]">
                  <div className="w-[100%] bg-[#D9D9D9]">
                    <p className='text-68-scale-mob md:text-68-scale text-center'>{contacts ? contacts.count > 10 ? contacts.count : `0${contacts.count}` : null}</p>

                  </div>
                </div>
                <p className="text-20-scale-mob md:text-18-scale-924  text-center pt-[10px]">{`Total no.of new users`} </p>
              </div>
              <div className="w-[45%]">
                <div className="w-[100%]  bg-[#D9D9D9]">
                  <p className='text-68-scale-mob md:text-68-scale text-center'>{followupusers ? followupusers.count > 10 ? followupusers.count : `0${followupusers.count}` : "00"}</p>

                </div>
                <p className="text-20-scale-mob md:text-18-scale-924 text-center pt-[10px]">Total no. of follow up today</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-[20px]">
            <div className="pt-40-scale md:pt-40-scale ">
              <GLobalSelect
                onChange={(value) => setUserType(value.value)}
                // defaultValue={{label:"New Users",value:"new"}}
                control={control} name='user_type' options={[{ label: "New Users", value: "new" }, { label: "Follow Up Users", value: "followup" }]} />
            </div>
            {/* <div className='flex w-[100%] justify-between'> */}
            {/* <div className=" w-[45%]">
            <GLobalSelect control={control} name='day' options={[{label:"Today",value:"Today"},{label:"Yesterday",value:"Yesterday"}]}/>
           </div> */}
            <div className="flex justify-between">
              <div className=" w-[48%]">
                <GlobalCalendar name="date" labelStyles={"text-20-scale-mob md:text-16-scale-924 font-[600] "} className={"h-50-scale-924-mob md:!h-45-scale-924"} selectedDate={startDate} handleDateChange={handleStartDateChange} disabled={userType === "followup" ? true : false}
                  maxDate={endDate}
                />

              </div>
              <div className=" w-[48%]"> <GlobalCalendar name="date" labelStyles={"text-20-scale-mob md:text-16-scale-924 font-[600] "} className={"h-50-scale-924-mob md:!h-45-scale-924"} selectedDate={endDate} handleDateChange={handleEndDateChange} calenderStyles={"right-0 "}
                minDate={startDate && startDate} />

              </div>
            </div>
            {!statsLoading ? stats && <div className="pt-[50px] px-[20px]">
              <Doughnut
                data={data}
                options={chatoptions}
                plugins={[textPlugins]}

              />
              <div className="flex items-center justify-center pt-[30px] md:font-16-scale-924 font-16-scale-mob">

                {/* <ul className="custom-list">{data.labels.map(val=><li key={val}>{val}</li>)}</ul> */}
                <div>
                  <ul style={{ listStyle: "none", paddingLeft: 0 }}>
                    {data.labels.map((val, index) => (
                      <li key={val} style={{
                        position: "relative",
                        paddingLeft: "2em",
                        marginBottom: "0.5em",
                        display: "flex",
                        alignItems: "center"
                      }}>
                        <span style={{
                          position: "absolute",
                          left: 0,
                          width: "1em",
                          height: "1em",
                          backgroundColor: backgroundColor[index],
                          borderRadius: "50%"
                        }}></span>
                        {val}<span className="font-[600] pl-[10px]">{data.datasets[0].data ? data.datasets[0].data[index] > 9 ? data.datasets[0].data[index] : data.datasets[0].data[index] ? `0${+data.datasets[0].data[index]}` : "00" : 0}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div> : <div className="flex items-center justify-center min-h-[300px]">

              <span className="loading loading-spinner loading-lg"></span>
            </div>}
          </div>
        </div>


      </div>



      {/* modal */}
      <dialog className="modal z-[1]" ref={modalRef} id="my_modal_7">
        <div className="modal-box w-[95%] md:min-w-[765px]">
          <div className="flex justify-between border-b-[1px] border-[black] items-center pb-[10px]" >
            <h3 className="text-[20px] font-inter pb-[10px]">Add New User</h3>

            <button onClick={() => { document.getElementById('my_modal_7').close(); reset() }} ><CloseIcon /></button>

          </div>
          <div className="p-[10px]">
            <div className="flex flex-wrap justify-between gap-y-[20px]">
              <div className="w-[100%] md:w-[47%]">
                <GlobalInput control={control} name="full_name" label="Full name" required={true}
                  rules={{ required: "Name is required" }} errors={errors.full_name} className="font-[!400]"
                />
              </div>
              <div className="w-[100%] md:w-[47%]">
                <GlobalInput control={control} name="age" label="Age" required={true}
                  errors={errors.age} max={99} type="number"
                  rules={{
                    required: "Age is required",
                    pattern: {
                      value: /^\d{1,2}$/,
                      message: 'Please Enter Valid Age',
                    },


                  }}
                />
              </div>
              <div className="w-[100%] md:w-[47%]">
                <GLobalSelect control={control} name="gender" label="Gender" options={[{ value: "female", label: "Female" }, { value: "male", label: "Male" }, { value: "other", label: "Other" }]}
                  menuPortalTarget={portalTarget} minHeight="45px" />
              </div>
              <div className="w-[100%] md:w-[47%]">
                <GlobalInput name="phone_number" label="Phone Number" control={control} className="font-[!400]"
                  required={true} rules={{
                    required: "Mobile Number is required", pattern: {
                      value: /^\d{10}$/,
                      message: 'Please Enter Valid Phone Number',
                    }
                  }} errors={errors.phone_number} phone_number={"true"} />
                {/* <GlobalInput control={control} name="phone_number" label="Phone Number" required={true} rules={{ required: "Phone Number is required" }} errors={errors.phone_number} /> */}
              </div>
              <div className="w-[100%] md:w-[47%]">
                <GLobalSelect control={control} name="assigned_to" label="Assigned To" options={options || []}
                  menuPortalTarget={portalTarget} minHeight="45px" />
              </div>
            </div>
            <div className="flex justify-end pt-[20px]">
              <button className="bg-[black] text-[white] w-[150px] md:w-[99px] h-[43px] rounded-[4px]" onClick={methods.handleSubmit(onSubmit)} disabled={addLoading}>{addLoading ? <span className="loading loading-spinner loading-sm"></span> : "Add"}</button>
            </div>

          </div>
          {/* <hr className="bg-[black] text-[black] color-[black]"/> */}
        </div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={() => reset()}>close</button>
        </form>
      </dialog>



      {/* Notes Modal */}
      <dialog className="modal rounded-[0]" id="my_modal_8">
        <div className="modal-box w-[95%] md:min-w-[800px] p-0 rounded-[0]">
          <div className="flex justify-between border-b-[1px] border-[black] items-center py-[15px] px-[15px]" >
            <h3 className="md:text-18-scale-924 text-18-scale-mob font-inter ">Add notes - <span className="font-[600]">{moment(Date.now()).format("DD MMM YYYY")}</span></h3>

            <button onClick={() => { document.getElementById('my_modal_8').close(); reset1(); setUserId("") }} ><CloseIcon /></button>

          </div>
          <div className="py-[15px]">


            <GlobalTextArea control={control1} name="note" required={true}
              rules={{ required: "Please enter note" }} errors={errors1.note}

              placeholder="Type your notes here"
              //  className="font-[!400] !border-0 note-input"
              className="h-[300px] note-input"
            />


            <hr className="border-black-100" />
            <div className="flex justify-end pt-[20px] px-[20px] gap-[20px]">
              <button className="border border-black-100 w-[168px] md:w-[168px] h-[43px] rounded-[4px] font-[600]" onClick={() => { document.getElementById('my_modal_8').close(); reset1(); setUserId("") }}>Discard</button>

              <button className="bg-[black] opacity-[50%] text-[white] w-[168px] md:w-[168px] h-[43px] rounded-[4px]" onClick={methods1.handleSubmit(submitNotes)} disabled={loading}>{notesLoading ? <span className="loading loading-spinner loading-sm"></span> : "Add"}</button>
            </div>

          </div>
          {/* <hr className="bg-[black] text-[black] color-[black]"/> */}
        </div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={() => reset1()}>close</button>
        </form>
      </dialog>
    </>
  )
}

export default Users