
import React, { forwardRef} from 'react';
import { Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select'
import DownArrowIcon from '../icons/DownArrowIcon';


const GlobalCreatableSelect = forwardRef(({ name, label,labelStyles, rules, control, className, errors, shadow,options,defaultValue = '', onChange,  isMulti,minHeight, ...props }, ref) => {
  const CustomDropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <DownArrowIcon className={minHeight&&!shadow?"":"md:!w-12-scale-924 md:!h-7-scale-924"}/>
      </components.DropdownIndicator>
    );
  };
    return (
      control && (
        <div>
          <div className="pb-[5px] pl-[5px]">
            <label className={labelStyles} >{label}{props.required&&<span className="text-[#FF4664] pl-[5px]">*</span>}</label>
          </div>
        <Controller
          name={name}
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={({ field }) => (
            <>
              <CreatableSelect
                
                menuPosition="fixed"
                menuPortalTarget={props.menuPortalTarget}
                components={{ DropdownIndicator: CustomDropdownIndicator }}
               isMulti={isMulti}
               onFocus={props.onFocus}
                 styles={{
                   control: (provided, state) => ({
                     // ...provided,
                     border:errors?'2px solid red':shadow?'0px':'2px solid #747474',   
                     // height: state.selectProps.menuIsOpen ? '90px' : '50px',
                     borderWidth:"2px",
                     minHeight: minHeight||"clamp(30px,calc((100vw / 1675) * 45),150px)",
                     height:minHeight,
                     justifyContent:"space-between",
                     display:"flex",
                     fontSize:minHeight&&!props.screen?"clamp(10px,calc((100vw / 1675) * 16),40px)":"clamp(10px,calc((100vw / 1675) * 16),40px)",
                     paddingRight:"10px",
                     // shadow:shadow?"0px 0px 6px 0px #0000001F":""
                    boxShadow:shadow?"0px 0px 6px 0px #0000001F":"",
                    borderRadius:"4px"
                     // borderColor:errors?r
                   }),
                   singleValue: (provided) => ({
                    ...provided,
                    color: "black", // Ensure color is applied to the selected value
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    color: "black", // Ensure color is applied to the selected value
                  }),
                   indicatorSeparator: () => ({
                     display: 'none', // Hide the line before the expand icon
                   }),
                   
                   menuList:()=>({
                    fontSize:"16px",
                    maxHeight:"180px",
                    overflowY: "auto",
                    // paddingBottom:"100px"
                    // marginBottom:"100px"
                  })
                 }}
                 // className={`${className}`}
                 // classNames={{
                 //   control: (state) =>
                 //   "border border-[2px] border-[black]"
                 // }}
                 // classNames={{
                 //   control: (state) =>
                 //     state.isFocused ? 'border-red-600' : 'border border-[2px] border-[black]',
                 // }}
                 {...field}
                 {...props}
                 ref={(e) => {
                   field.ref(e);
                   if (ref) {
                     ref.current = e;
                   }
                 }}
                 // value={field.value||props.value}
                 // value={options.find(option => option.value === field.value)}
                 options={options} 
                 placeholder={props.placeholder||""}
                 // className={`border ${errors?"border-[red]":"border-[gray]"} h-[45px] w-[100%] rounded-[10px] pl-[10px] ${className}`}
                 onChange={(e) => {
                   field.onChange(e); // Update the field value in the form
                   if (onChange) {
                     onChange(e); // Call the custom onChange function
                   }
                 }}
               />
               {errors && <span style={{ color: 'red' }}>{errors.message}</span>}
            </>
          )}
        />
      </div>
    )
  );
});

export default GlobalCreatableSelect;


