import React from "react";

function AddNoteIcon({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="20"
      // height="20"
      fill="none"
      viewBox="0 0 20 20"
      className={`w-18-scale-mob md:w-18-scale-924 h-20-scale-mob md:h-20-scale-924 ${className}`}

    >
      <path
        fill="#000"
        d="M2 18c-.55 0-1.02-.196-1.412-.587A1.93 1.93 0 010 16V2C0 1.45.196.98.588.588A1.93 1.93 0 012 0h14c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v6.7a8.13 8.13 0 00-.975-.387A6.202 6.202 0 0016 8.075V2H2v14h6.05c.05.367.13.717.238 1.05.109.333.238.65.387.95H2zm0-3v1V2v6.075V8v7zm2-1h4.075c.05-.35.13-.692.238-1.025.109-.333.23-.658.362-.975H4v2zm0-4h6.1a7.65 7.65 0 011.788-1.25A7.048 7.048 0 0114 8.075V8H4v2zm0-4h10V4H4v2zm11 14c-1.383 0-2.562-.488-3.537-1.463-.975-.975-1.462-2.154-1.463-3.537 0-1.383.487-2.562 1.463-3.537C12.439 10.488 13.618 10 15 10c1.382 0 2.561.488 3.538 1.463.977.975 1.464 2.154 1.462 3.537-.002 1.383-.49 2.562-1.463 3.538-.973.976-2.152 1.463-3.537 1.462zm-.5-2h1v-2.5H18v-1h-2.5V12h-1v2.5H12v1h2.5V18z"
      ></path>
    </svg>
  );
}

export default AddNoteIcon;