import * as events from "../Events";
const token=localStorage.getItem("token")

export const getProvinces = () => {
    // console.log(token)
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/provinces/`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
          }
       
      });
      if (!response.ok) {
        const resError = await response.json();
        if (resError) {
          throw new Error(resError);
        }
      } else {
        const resData = await response.json();

        // const users = resData;
        dispatch({ type: events.GET_ALL_PROVINCE,payload:resData });
        return resData ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getCities = (id) => {
    // console.log(token)
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/cities/?province_id=${id}`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
          }
       
      });
      if (!response.ok) {
        const resError = await response.json();
        if (resError) {
          throw new Error(resError);
        }
      } else {
        const resData = await response.json();

        // const users = resData;
        dispatch({ type: events.GET_CITIES,payload:resData });
        return resData ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
//GET_AI_INSIGHTS
export const getAiinsights = (phone) => {
  // console.log(token)
return async (dispatch) => {
  try {
    const response = await fetch(`http://164.52.209.83:5006/user_insights?user_id=${phone}`, {
      method: "GET",
      headers: {
                   'Content-Type': 'application/json' 
        }
     
    });
    if (!response.ok) {
      dispatch({ type: events.GET_AI_INSIGHTS,payload:null });
      const resError = await response.json();
      let jsonError=""
      if(typeof(resError)==="object"){
        console.log("resError","hello",resError)
        jsonError= JSON.stringify(resError)
      }else{
        jsonError=resError
      }
      if (jsonError) {
        throw new Error(jsonError);
      }
     
    }  else {
      const resData = await response.json();

      dispatch({ type: events.GET_AI_INSIGHTS,payload:resData });
      return resData ? resData : false;
    }
  } catch (err) {
    throw new Error(err.message);
  }
};
};
// https://bot.swarnaayu.com/api/conversation_history?phone_number=919573310101

// export const getChat=(phone)=>{
//   return async (dispatch) => {
//     try {
//       const response = await fetch(`https://bot.swarnaayu.com/api/conversation_history?phone_number=${phone}`, {
//         method: "GET",
//         headers: {
//                      'Content-Type': 'application/json' 
//           }
       
//       });
//       if (!response.ok) {
//         dispatch({ type: events.GET_CHAT,payload:response });
//         const resError = await response.json();
//         let jsonError=""
//         if(typeof(resError)==="object"){
          
//           jsonError= JSON.stringify(resError)
//         }else{
//           jsonError=resError
//         }
//         if (jsonError) {
//           throw new Error(jsonError);
//         }
       
//       }  else {
//         const resData = await response.json();
  
//         dispatch({ type: events.GET_CHAT,payload:resData });
//         return resData ? resData : false;
//       }
//     } catch (err) {
//       throw new Error(err.message);
//     }
//   };
// }

export const getChat = (phone) => {
    // console.log(token)
  return async (dispatch) => {
    try {
      const response = await fetch(`https://bot.swarnaayu.com/api/conversation_history?phone_number=${phone}`, {
        method: "GET",
        headers: {
            // 'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' 
          }
       
      });
      if (!response.ok) {
        const resError = await response.json();   
         
        if (resError) {
         
          throw new Error(resError);
        }
      } else {
        const resData = await response.json();

        // const users = resData;
        dispatch({ type: events.GET_CHAT,payload:resData });
        return resData ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};



// SEND_FEEDBACK
export const sendFeedback=(data)=>{
  return async (dispatch) => {
       try {
         const response = await fetch(`https://bot.swarnaayu.com/api/feedback`
, {
           method: "POST",
           headers: {
              //  'Authorization': `Bearer ${token}`,
               'Content-Type': 'application/json' 
             },
            body:JSON.stringify(data)
         });
         if (!response.ok) {
           const resError = await response.json();
           let jsonError=""
           if(typeof(resError)==="object"){
             jsonError= JSON.stringify(resError)
           }else{
             jsonError=resError
           }
           if (jsonError) {
             throw new Error(jsonError);
           }
         }  else {
           const resData = await response.json();
         
           // const users = resData;
           dispatch({ type: events.EDIT_CHAT_MODE,payload:resData});
           return resData ? resData : false;
         }
       } catch (err) {
         throw new Error(err.message);
       }
     };
}
export const updatechatmode=(data)=>{
  return async (dispatch) => {
       try {
         const response = await fetch(`https://bot.swarnaayu.com/api/companion_mode/`
, {
           method: "PUT",
           headers: {
               'Authorization': `Bearer ${token}`,
               'Content-Type': 'application/json' 
             },
            body:JSON.stringify(data)
         });
         if (!response.ok) {
           const resError = await response.json();
           let jsonError=""
           if(typeof(resError)==="object"){
             jsonError= JSON.stringify(resError)
           }else{
             jsonError=resError
           }
           if (jsonError) {
             throw new Error(jsonError);
           }
         }  else {
           const resData = await response.json();
         
           // const users = resData;
           dispatch({ type: events.EDIT_CHAT_MODE,payload:resData});
           return resData ? resData : false;
         }
       } catch (err) {
         throw new Error(err.message);
       }
     };
}

export const getCompanionMode = (phone) => {
 return async (dispatch) => {
   try {
     // Fix the URL to match the working endpoint
     const response = await fetch(`https://bot.swarnaayu.com/api/companion_mode?phone_number=${phone}`, {
       method: "GET",
       headers: {
         'Content-Type': 'application/json'
       },
      //  mode: 'cors'
     });
     
     if (!response.ok) {
       const resError = await response.json();
       console.error('API Error:', resError);
       throw new Error(resError?.error || 'Failed to fetch mode');
     }
     
     const resData = await response.json();
     dispatch({ type: events.GET_COMPANION_MODE, payload: resData });

     return resData?resData:false;
   } catch (err) {
     console.error('Error fetching companion mode:', err);
     throw err;
   }
 };
};
export const editChat = (data) => {
  return async (dispatch) => {
    try {
      // Fix the URL to match the working endpoint
      const response = await fetch(`https://bot.swarnaayu.com/api/edit/message`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
    
        body:JSON.stringify(data)
      });
      
      if (!response.ok) {
        const resError = await response.json();
        console.error('API Error:', resError);
        throw new Error(resError?.error || 'Failed to fetch mode');
      }
      
      const resData = await response.json();
      dispatch({ type: events.GET_COMPANION_MODE, payload: resData });
      return resData?resData:false;
    } catch (err) {
      console.error('Error fetching companion mode:', err);
      throw err;
    }
  };
 };

 //send msg
 export const sendMsg = (data) => {
  return async (dispatch) => {
    try {
      // Fix the URL to match the working endpoint
      const response = await fetch(`https://bot.swarnaayu.com/api/send_message`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
    
        body:JSON.stringify(data)
      });
      
      if (!response.ok) {
        const resError = await response.json();
        console.error('API Error:', resError);
        throw new Error(resError?.error || 'Failed to fetch mode');
      }
      
      const resData = await response.json();
      dispatch({ type: events.SEND_MSG, payload: resData });
      return resData?resData:false;
    } catch (err) {
      console.error('Error fetching companion mode:', err);
      throw err;
    }
  };
 };