import React from 'react';
import EditIcon from '../../icons/EditIcon';
import GlobalTextArea from '../GlobalTextArea';
import { BiDislike, BiLike } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import moment from 'moment';

const Chat = ({
  data, 
  index, 
  length, 
  handleEditMsg, 
  editChatId, 
  control, 
  handleRevert, 
  handleSave, 
  handleSendFeedback, 
  checked 
}) => {

  // Helper function to determine background color based on sender
  const getBackgroundColor = (sender) => {
    switch (sender) {
      case "AAYU":
      case "ASSISTANT":
        return "bg-gray-200";
      case "INTERN":
        return "bg-red-100";
      case "USER":
        return "bg-gray-100";
      default:
        return "bg-gray-100";
    }
  };



  return editChatId === data?.message_id ? (
    <div className="flex justify-end items-end gap-[10px]">
      <div className="md:w-[55%]">
        <GlobalTextArea control={control} name={`message_${data.message_id}`} className="bg-gray-200" />
      </div>
      <button
        className="bg-green-500 rounded-[20px] w-[80px] text-white h-[40px]"
        onClick={() => handleSave(data.message_id)}
      >
        Send
      </button>
      <button
        className="bg-red-500 rounded-[20px] w-[80px] text-white h-[40px]"
        onClick={() => handleRevert(data.content)}
      >
        Revert
      </button>
    </div>
  ) : (
    <div
      className={`group flex gap-[10px] ${
        data.sender === "AAYU" || data.sender === "ASSISTANT" || data.sender === "INTERN"
          ? "place-self-end"
          : "place-self-start"
      } ${getBackgroundColor(data.sender)} max-w-[95%] md:max-w-[55%] rounded-[20px] py-[8px] px-[20px]`}
    >
      <div className="relative flex">
        {data.sender === "AAYU" && (
          <div className="absolute hidden group-hover:flex left-[93%] bottom-[100%] md:bottom-[100%] gap-[10px]">
            <button onClick={() => handleSendFeedback(data?.message_id, "dislike")}>
              <BiDislike size={30} className="text-gray-500 hover:text-red-500" />
            </button>
            <button onClick={() => handleSendFeedback(data?.message_id, "like")}>
              <BiLike size={30} className="text-gray-500 hover:text-green-500" />
            </button>
          </div>
        )}
        <div>
        <p className={`text-18-scale-mob md:text-18-scale-924 break-words max-w-[100%] ${
          data.sender === "INTERN" ? "text-red-700" : ""
        }`}>
          {data.content}
        </p>
    <p className="text-14-scale-mob md:text-14-scale-924 text-end text-black-100">{moment(data.timestamp).format("hh:mm:ss A DD/MM/YYYY")}</p>
     </div>
      {checked&& index === length - 1 && data.sender === "ASSISTANT" && (
        <div className="flex gap-[10px] items-start">
        <button onClick={() => handleEditMsg(data.message_id, data.content)}>
          <EditIcon />
        </button>
         <button onClick={() => handleSave(data.message_id,data.content)}>
         {/* <CiCircleCheck  size={24} className="text-[#000]"/> */}
         <FaRegCircleCheck size={18} />

       </button>
       </div>
       
      )}
    </div>
    </div>
  );
};

// Use React.memo with custom comparison
export default React.memo(Chat, (prevProps, nextProps) => {
  return (
    prevProps.editChatId === nextProps.editChatId &&
    prevProps.data.content === nextProps.data.content &&
    prevProps.data.id === nextProps.data.id &&
    prevProps.checked===nextProps.checked&&
    prevProps.data.sender===nextProps.data.sender
  );
});
