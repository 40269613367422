import React, { useEffect, useState } from 'react'
import NavBar from '../../../components/navbar/NavBar';
import BackIcon from '../../../icons/BackIcon';
import { useNavigate, useParams, useLocation } from "react-router-dom"

//redux
import { useDispatch, useSelector } from 'react-redux';
import {  getUserDetails } from '../../../redux/actions/usersactions';
import { getAiinsights } from '../../../redux/actions/commonActions';
import moment from 'moment';
import { Outlet } from 'react-router-dom'
//css
import "../users.css"
const Index = () => {
    const token = localStorage.getItem("token")
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const params = useParams();
    const { id } = params;
    const location = useLocation()
    const formData = useSelector((state) => state.users.user_details);
    const fetchUSerDetails = async () => {
        try {
            await dispatch(getUserDetails(id))
        } catch (err) {
            console.log(err)

        }
    }
    useEffect(() => {
        if (id) {
            fetchUSerDetails()
        }

    }, [])
    const fetchAiInsights = async () => {
        try {
            await dispatch(getAiinsights(formData?.phone_number))
        
        } catch (err) {
            console.log(err.message)
        }
    }
    useEffect(() => {
        if (formData) {
            fetchAiInsights()
        }
    }, [formData])
    const data = {
        persnol_info: {
            "Senior unique ID": id,
            Name: formData?.full_name,
            Age: formData?.age,
            "Mobile number": formData?.phone_number && formData.phone_number.replace(/^91/, ''),
            "Date of birth": formData?.dob && moment(formData.dob).format("DD/MM/YYYY"),
            // Location: formData?.address,
            "Registered date": formData?.created_at && moment(formData.created_at).format("MMM DD,YYYY"),
            "Last contacted": formData?.last_call_date && moment(formData.last_call_date).format("MMM DD,YYYY")
        }
    }

    const handleTopNavigation = (value) => {
        if (value === "generalinfo") {
            navigate(`/user/${id}`)
        } else if (value === "aiinsights") {
            navigate(`/user/${id}/aiinsights/healthinfo`)
        } else {
            navigate(`/user/${id}/${value}`)
        }
    }
    const topNavOpt = [{ name: "General information", value: "generalinfo" }, { name: "Notes", value: "notes" }, { name: "Call information", value: "callsinfo" },

    // {name:"Reports",value:"reports"},
    // {name:"Call information",value:"callinfo"},
    { name: "AI insights", value: "aiinsights" },
    {name:"Chat management",value:"chat"}
    ];
    if (!token) {
        navigate("/")
    }
    return (
        <div>
            <NavBar />

            <div className="pt-91-scale px-20-scale-mob md:px-50-scale flex flex-col form-fields  w-[100%]">
                <div className="flex gap-x-4 items-center cursor-pointer" onClick={() => navigate(-1)}>
                    <BackIcon />
                    <p className="text-24-scale-mob md:text-24-scale font-[600]">Back</p>
                </div>
                <div className="card_shadow  py-20-scale-mob md:py-20-scale-924 px-20-scale-mob md:px-50-scale-924 bg-white-100 flex flex-col form-fields">
                    <div className='flex justify-between items-center'>
                        <p className='text-24-scale-mob md:text-24-scale-924 font-[600]'>Personal Information</p>
                        <button type="button" className="md:h-30-scale-924 h-30-scale-mob w-158-scale-mob md:w-158-scale-924 border border-1 border-black-100 rounded-[6px]  text-14-scale-mob md:text-14-scale-924 bg-[#F5F5F5]" onClick={() => navigate(`/user/add/${id}`)}>Edit details</button>

                    </div>
                    <div className="flex form-fields flex-wrap flex-col md:flex-row">
                        {data && Object.keys(data.persnol_info).map(val => <div key={val} className="flex md:flex-col gap-y-[5px] md:w-[20%]  ">
                            <p className="text-16-mob md:text-17-scale-924 font-[600]">{val} <span className="md:hidden">{" "}: </span> </p> 
                            <p className="text-16-mob md:text-16-scale-924 text-[#767676]">{data.persnol_info[val] || "-"}</p>
                        </div>)}
                    </div>
                </div>
                {/* topNavOpt */}
                <div className="flex gap-x-[40px] flex-col md:flex-row border-b-[2px] sticky-nav">
                    {topNavOpt.map(val => <div key={val.value} className={""}><p key={val.value} onClick={() => handleTopNavigation(val.value)} className={`text-16-scale-mob md:text-16-scale-924 cursor-pointer font-[600] ${location.pathname.includes(val.value) || (val.value === "generalinfo" && (!location.pathname.includes("notes") && !location.pathname.includes("callsinfo") && !location.pathname.includes("aiinsights")&&!location.pathname.includes("chat"))) ? "border-b-[2px] border-black-100 text-black-100" : "text-[#8A8A8A]"} p-20-scale-mob md:p-20-scale-924`}>{val.name}</p></div>)}
                </div>

                <Outlet />


            </div>

        </div>
    )
}

export default Index