import React from 'react'
import CycleIcon from '../../../icons/CycleIcon'
import BedIcon from '../../../icons/BedIcon'
import { formatString } from '../../../methods'
import ListIcon from '../../../icons/ListIcon'
import FrequencyIcon from '../../../icons/FrequencyIcon'
import HealthCare from '../../../icons/HealthCare'
import CusineIcon from '../../../icons/CusineIcon'
import MealIcon from '../../../icons/MealIcon'
import SugarIcon from '../../../icons/SugarIcon'
import SaltsIcon from '../../../icons/SaltsIcon'
import CarbsIcon from '../../../icons/CarbsIcon'
import DietIcon from '../../../icons/DietIcon'
import EventsIcon from '../../../icons/EventsIcon'
import MusicIcon from '../../../icons/MusicIcon'
import GardeningIcon from '../../../icons/GardeningIcon'
import ArtsAndCraft from '../../../icons/ArtsAndCraftIcon'
import HobbiesIcon from '../../../icons/HobbiesIcon'
import { useNavigate, useParams } from 'react-router-dom'
//redux
import { useSelector } from 'react-redux'
import SideNav from './SideNav'


const Hobbies = () => {
    const navigate = useNavigate();
    const params = useParams()
    const { id } = params
    const data = useSelector((state) => state.users.user_details);
    const otherHobbies = data && data.hobbies && data.hobbies.find(val => val.hobby === "others");
    const socialEvents=data&&data.hobbies&&data.hobbies[0]?.social_events_and_activity

    return (
        <div className="flex justify-between flex-col md:flex-row">
            <div className='md:w-[20%]'>
                <SideNav />
            </div>
            <div className="md:w-[77%] flex-col form-fields">
                <div className="flex justify-end" >
                    <button type="button" className="md:h-30-scale-924 h-30-scale-mob w-158-scale-mob md:w-158-scale-924 border border-1 border-black-100 rounded-[6px]  text-14-scale-mob md:text-14-scale-924 bg-[#F5F5F5]" onClick={() => navigate(`/user/add/${id}/hobbiesinfo`)}>Edit details</button>
                </div>



                <div className="bg-white-100 rounded-[10px] md:py-20-scale-924 py-20-scale-mob md:px-10-scale-924 px-10-scale-mob flex flex-col form-fields">
                    <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob form-fields flex flex-col">
                        <div className="flex items-center gap-x-[10px] ">
                            <HobbiesIcon />
                            <p className='text-16-scale-mob md:text-16-scale-924 font-[600]'>Hobbies</p>
                        </div>

                        <div className="flex gap-[30px] flex-wrap" >
                            {/* {data.hobbies && data.hobbies.map(val => (

                                            <div className="bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] w-[30%] text-14-scale-mob md:text-14-scale pb-20-scale-mob md:pb-20-scale-924 flex flex-col gap-[10px]" key={val.condition}>

                                                {val.hobby&&formatString(val.hobby)}
                                            </div>
                                        ))} */}
                            {data?.hobbies?.map((val, index) =>
                                <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob md:pb-20-scale-924 w-[100%] md:w-[30%]" key={val.id}>


                                    <div className="text-14-scale-mob md:text-14-scale-924">
                                        <p >{val.hobby && formatString(val.hobby)}</p>

                                    </div>
                                    <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                        {val.hobby === "arts_and_craft" ? < ArtsAndCraft /> :
                                            val.hobby === "music" ? <MusicIcon /> : <GardeningIcon />}
                                    </div>
                                </div>

                            )}
                        </div>

                        {otherHobbies && <p><div className="bg-white-100 md:p-20-scale-924 p-20-scale-mob rounded-[5px]">
                            <p className="md:text-14-scale-924 text-14-scale-mob">{otherHobbies.other_hobbies}</p>
                        </div></p>}
                        <div className="md:w-356-scale-924 rounded-[5px] flex justify-between bg-white-100 p-20-scale-mob md:p-20-scale-924 ">
                            <div>
                                <p className="text-14-scale-mob md:text-14-scale-924 font-[600] ">Social event & activity</p>
                                <p className="text-14-scale-mob md:text-14-scale-924">{socialEvents || "-"}</p>
                            </div>
                            <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                <EventsIcon />
                            </div>
                        </div>
                    </div>
                    {/* Diet */}
                    <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob form-fields flex flex-col">
                        <div className="flex items-center gap-x-[10px]">
                            <DietIcon />
                            <p className='text-16-scale-mob md:text-16-scale-924 font-[600] '>Diet</p>
                        </div>

                        <div className="flex gap-[30px] flex-wrap" >

                            {data?.diet_restrictions?.map((val, index) =>
                                <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob md:pb-20-scale-924 w-[100%] md:w-[30%] " key={val.id}>


                                    <div className="text-14-scale-mob md:text-14-scale-924">
                                        <p >No/{val.restriction && formatString(val.restriction)}</p>

                                    </div>
                                    <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                        {val.restriction === "less_carbs" ? <CarbsIcon /> :
                                            val.restriction === "less_sugar" ? <SugarIcon /> : <SaltsIcon />}
                                    </div>
                                </div>

                            )}
                        </div>
                        {data&&data.elergy && <p><div className="bg-white-100 md:p-20-scale-924 p-20-scale-mob rounded-[5px]">
                                        <p className="md:text-14-scale-924 text-14-scale-mob">{data.elergy }</p>
                                    </div></p>}
                        <div className="flex gap-[30px] flex-col md:flex-row">

                            <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob md:pb-20-scale-924 md:w-[40%] " >

                                <div className="text-14-scale-mob md:text-14-scale-924">
                                    <p className="font-[600]">Meal Preference</p>
                                    <p >{(data && data.meal_preferences
                                        && formatString(data.meal_preferences.preference)) || "-"}</p>

                                </div>
                                <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                    <MealIcon />
                                </div>
                            </div>
                            <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob md:pb-20-scale-924 md:w-[40%]" >

                                <div className="text-14-scale-mob md:text-14-scale-924 ">
                                    <p className="font-[600]">Cuisine Preference</p>
                                    <p >{(data && data.cuisine_preferences && formatString(data.cuisine_preferences.cuisine)) || "-"}</p>

                                </div>
                                <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                    <CusineIcon />
                                </div>
                            </div>
                        </div>


                    </div>
                    {/* Exercise */}
                    <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob form-fields flex flex-col">
                        <div className="flex items-center gap-x-[10px]">
                            <HealthCare />
                            <p className='text-16-scale-mob md:text-16-scale-924 font-[600] '>Exercise</p>
                        </div>



                        <div className='flex gap-[30px] flex-col md:flex-row'>
                            <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob md:pb-20-scale-924 md:w-[40%]" >


                                <div className="text-14-scale-mob md:text-14-scale-924">
                                    <p className="font-[600]">How often do you exercise</p>
                                    <p >{data?.exercise_details?.frequency || "-"}</p>

                                </div>
                                <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                    <FrequencyIcon />
                                </div>
                            </div>
                            <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob md:pb-20-scale-924 md:w-[40%]" >


                                <div className="text-14-scale-mob md:text-14-scale-924">
                                    <p className="font-[600]">Types of exercise</p>
                                    {/* <p >{data?.exercise_details?.type_of_exercise?.map((val,index) => index+1!==data?.exercise_details?.type_of_exercise.length?val + ",":val)||"-"}</p> */}
                                    {data?.exercise_details?.type_of_exercise?.map((val, index) =>
                                        index + 1 !== data?.exercise_details?.type_of_exercise.length
                                            ? `${val}, `
                                            : val
                                    ) || "-"}
                                </div>
                                <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                    <ListIcon />
                                </div>
                            </div>
                        </div>





                    </div>


                    {/* sleep routine */}
                    <div className="bg-[#F6F6F6] rounded-[10px] md:pt-20-scale-924 pt-20-scale-mob md:pb-20-scale-924 pb-20-scale-mob md:px-20-scale-924 px-20-scale-mob form-fields flex flex-col">
                        <div className="flex items-center gap-x-[10px]">
                            <BedIcon />
                            <p className='text-16-scale-mob md:text-16-scale-924 font-[600] '>Sleep routine</p>
                        </div>



                        <div className='flex gap-[30px] flex-col md:flex-row'>
                            <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob md:pb-20-scale-924 md:w-[40%]" >


                                <div className="text-14-scale-mob md:text-14-scale-924">
                                    <p className="font-[600]">Sleep pattren</p>
                                    <p >{(data?.sleep_pattern && formatString(data.sleep_pattern.pattern) )|| "-"}</p>

                                </div>
                                <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                    <BedIcon />
                                </div>
                            </div>
                            <div className="flex justify-between items-center bg-white-100 md:p-10-scale-924 p-10-scale-mob rounded-[5px] pb-20-scale-mob md:pb-20-scale-924 md:w-[40%]" >


                                <div className="text-14-scale-mob md:text-14-scale-924">
                                    <p className="font-[600]">Sleep duration</p>
                                    <p >{data?.sleep_pattern?.duration || "-"}</p>

                                </div>
                                <div className="bg-[#D9D9D9] rounded-full md:w-41-scale-924 w-41-scale-mob md:h-41-scale-924 h-41-scale-mob flex items-center justify-center">
                                    <CycleIcon />
                                </div>
                            </div>
                        </div>





                    </div>
                </div>


            </div>
        </div>


    )
}

export default Hobbies