import React from "react";

function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
    //   width="16"
    //   height="18"
      fill="none"
      viewBox="0 0 16 18"
      className={"w-15-scale-mob md:w-15-scale-924 h-18-scale-mob md:18-scale-924"}
    >
      <path
        fill="#000"
        d="M3 18c-.55 0-1.02-.196-1.412-.587A1.93 1.93 0 011 16V3H0V1h5V0h6v1h5v2h-1v13c0 .55-.196 1.021-.587 1.413A1.92 1.92 0 0113 18H3zM13 3H3v13h10V3zM5 14h2V5H5v9zm4 0h2V5H9v9z"
      ></path>
    </svg>
  );
}

export default DeleteIcon;