import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import GlobalInput from '../../components/GlobalInput';
import { useForm } from 'react-hook-form';
import GLobalSelect from '../../components/GlobalSelect';
import DeleteIcon from '../../icons/DeleteIcon';
import AddFillIcon from '../../icons/AddFillIcon';
import FormFooter from './FormFooter';
import { useDispatch, useSelector } from 'react-redux';
import { medicalInfo } from '../../redux/actions/FormActions';

import "./form.css"
import { editUserDeatils, getUserDetails } from '../../redux/actions/usersactions';
import { formatString } from '../../methods';
import { toast } from 'react-toastify';
import { CreatableSelect } from 'react-select/creatable';
import GlobalCreatableSelect from '../../components/GlobalCreatableSelect';
import { usePath } from '../../states/PathContext';

const MedicalInfo = () => {
    //button loader
    const { path } = usePath()
    const [loading, setLoading] = useState(false)
    const formData = useSelector((state) => state.users.user_details);
    const dispatch = useDispatch();
    const params = useParams();
    const { id } = params;
    const navigate = useNavigate()
    const methods = useForm({ mode: "all" });
    const [careGiverType, setCareGiverType] = useState([]);
    const [showCheckbox, setShowCheckbox] = useState([])
    const [careGiverTeam, setCareGiverTeam] = useState([{ id: 1, source: "application" }]);
    const [medicalConditions, setMedicalConditions] = useState([{ id: 1, source: "application" }])
    const { control, formState: { errors }, setValue, getValues } = methods;
    const [state, setState] = useState({})
    const cheboxlabels = ["Type 2 Diabetes", "Hypertension", "Cholesterol", "Arthritis", "Heart Diseases", "Memory Loss Conditions", "Asthma", "Kidney Diseases", "Other Conditions", "None"]
    const handleAddCareGiverTeam = () => {
        setCareGiverTeam([...careGiverTeam, { id: Date.now().toString(), source: "application" }])
    }
    const handleDeleteCareGiverTeam = (value) => {
        if (careGiverTeam.length === 1) {
            setValue(`expert_type_${id}`, "");
            setValue(`expert_name_${id}`, "");
            setValue(`expertise_${id}`, "");
            setCareGiverTeam([{ id: Date.now().toString(), source: "application" }])
        } else {
            setCareGiverTeam(careGiverTeam.filter(val => val.id !== value))
        }
    }
    const handleAddMedicalCondition = () => {

        setMedicalConditions([...medicalConditions, { id: Date.now().toString(), source: "application" }])

    }

    const onSubmit = async (data) => {
        // if (state.medical_history && state.medical_history.length > 0) {
            setLoading(true)
            const reqData = formattedData()

            try {
                await dispatch(editUserDeatils(id, reqData));
                await dispatch(getUserDetails(id))
                navigate(`/user/add/${id}/surgicalinfo`, { state: { from: path } })
            } catch (err) {
                console.log(err);
                toast.error(err.message)
            }
            setLoading(false)
        // } else {
        //     toast.error("Please Select medical conditions")
        // }
    }
    const handleCheckboxChange = (event) => {

        const { value } = event.target;

        const findValue = formData &&
            formData.medical_conditions &&
            formData.medical_conditions.length > 0 &&
            formData.medical_conditions.find(val => val.medical_condition === value.toLowerCase().replace(/ /g, "_"));

        const checked = { name: value };
        if (findValue) {
            checked.id = findValue.id
        }

        if (event.target.type === "checkbox") {
            setState({
                ...state, medical_history: state.medical_history ? state.medical_history.some(obj => obj.name === value) ? state.medical_history.filter(val => val.name !== value) : [...state.medical_history, checked] : [checked]
            })
        }
    };
    const handleMedicalOption = () => {
        setState({ ...state, condition_options: state.medical_history ? state.medical_history.filter(val => val.name !== "None" && val.name !== "Other Conditions").map(val => { return ({ value: val.name, label: val.name }) }) : [] })
    }

    //defaultvalues in form
    useEffect(() => {
        if (formData && formData.id === parseInt(id)) {
            const { medications, medical_conditions, professional_caregiver_team } = formData;
            medications.length > 0 && setMedicalConditions(medications.map(val => ({ id: val.id, source: "database" })));
            professional_caregiver_team.length > 0 && setCareGiverTeam(professional_caregiver_team.map(val => ({ id: val.id, source: "database" })))
            //   const medical_history=medical_conditions.map(val=>formatString(val.medical_condition));
            let medical_history = []
            for (let val of medical_conditions) {
                val.medical_condition && medical_history.push({ name: val.medical_condition === "no_condition" ? "None" : formatString(val.medical_condition), id: val.id });
                val.medical_condition && setValue(formatString(val.medical_condition), val.year);
                val.medical_condition === "other_conditions" && setValue("other_conditions", val.other_diseases)
            }
            medical_conditions.length > 0 && setState({ ...state, medical_history })
            for (let val of medications) {
                val.condition && setValue(`condition_${val.id}`, { label: formatString(val.condition), value: formatString(val.condition) });
                val.medicine_name && setValue(`medicine_name_${val.id}`, val.medicine_name);
                val.dosage_frequency && setValue(`dosage_frequency_${val.id}`, { label: val.dosage_frequency, value: val.dosage_frequency })
            }
            let careGiver = [];
            let checkboxes = [];
            for (let val of professional_caregiver_team) {

                setValue(`expert_name_${val.id}`, val.expert_name);
                setValue(`expertise_${val.id}`, val.expertise);
                setValue(`phone_number_${val.id}`, val.phone_number);
                val.expert_type && setValue(`expert_type_${val.id}`, { label: formatString(val.expert_type), value: formatString(val.expert_type) });
                if (val.expert_type === "primary_physician") {
                    checkboxes = [...checkboxes, val.id]
                    if (val.is_agreed) {
                        careGiver = [...careGiver, val.id]
                    }
                }
            }
            setShowCheckbox(checkboxes);
            setCareGiverType(careGiver)


        }
    }, [formData])

    // primary physicain checkbox
    const handlePrimaryPhysician = (value, id) => {
        if (value === "Primary Physician") {
            setShowCheckbox([...showCheckbox, id])
        } else {
            setShowCheckbox(showCheckbox.filter(val => val !== id))
        }

    }
    const handleCGcheckbox = (id) => {
        if (careGiverType.includes(id)) {
            setCareGiverType(careGiverType.filter(val => val !== id))
        } else {
            setCareGiverType([...careGiverType, id])
        }

    }
    const onCloseForm = async () => {
        const reqData = formattedData();

        try {
            await dispatch(editUserDeatils(id, reqData));
            await dispatch(getUserDetails(id))
            path === "/users" ? navigate(`/users`) : navigate("/usermanagement")
        } catch (err) {
            console.log(err);
            toast.error(err.message)
        }
    }
    const handleBackClick = async () => {
        const reqData = formattedData();
        try {
            await dispatch(editUserDeatils(id, reqData));
            await dispatch(getUserDetails(id))
            navigate(-1)
        } catch (err) {
            console.log(err);
            toast.error(err.message)
        }
    }
    //formatData for backend
    const formattedData = () => {
        const medical_conditions = state.medical_history&&state.medical_history.length>0 && state.medical_history.map(val => {
            const other_diseases = val.name === "Other Conditions" ? getValues("other_conditions") : null;

            const result = {
                medical_condition: val.name === "None" ? "no_condition" : val.name.toLowerCase().replace(/ /g, "_"),

            };
            if (val.id) {
                result.id = val.id
            }
            if (other_diseases != null) {
                result.other_diseases = other_diseases;
            } else {
                result.year = parseInt(getValues(val.name))
            }

            return result
        })

        let medications = [];

        medicalConditions.forEach(val => {
            const result = {}
            if (getValues(`condition_${val.id}`)) {
                result.condition = getValues(`condition_${val.id}`).value.toLowerCase().replace(/ /g, "_")
            }
            if (getValues(`medicine_name_${val.id}`)) {
                result.medicine_name = getValues(`medicine_name_${val.id}`)
            }
            if (getValues(`dosage_frequency_${val.id}`)) {
                result.dosage_frequency = getValues(`dosage_frequency_${val.id}`).value
            }
            if (val.source === "database") {
                result.id = val.id
            }
            if (Object.keys(result).length > 1) {
                medications = [...medications, result]
            }
        })


        let professional_caregiver_team = [];
        careGiverTeam.forEach(val => {
            const result = {}
            if (getValues(`expert_type_${val.id}`)) {
                result.expert_type = getValues(`expert_type_${val.id}`).value.toLowerCase().replace(/ /g, "_")
            }
            if (getValues(`expert_name_${val.id}`)) {
                result.expert_name = getValues(`expert_name_${val.id}`)
            }
            if (getValues(`expertise_${val.id}`)) {
                result.expertise = getValues(`expertise_${val.id}`)
            }
            if (getValues(`phone_number_${val.id}`)) {
                result.phone_number = getValues(`phone_number_${val.id}`)
            }
            if (val.source === "database") {
                result.id = val.id
            }
            if (getValues(`expert_type_${val.id}`) && getValues(`expert_type_${val.id}`).value === "Primary Physician") {

                if (careGiverType.includes(val.id)) {
                    result.is_agreed = true
                } else {
                    result.is_agreed = false
                }
            }
            if (Object.keys(result).length > 1) {
                professional_caregiver_team = [...professional_caregiver_team, result]
            }
        })
        const reqData = {
            medical_conditions,
            // medications: medications,
            // professional_caregiver_team:professional_caregiver_team

        }

        if (medications.length) {
            reqData.medications = medications

        }
        //  else {
        //     reqData.medications = [{}]
        // }
        if (professional_caregiver_team.length) {
            reqData.professional_caregiver_team = professional_caregiver_team
        } 
        // else {
        //     reqData.professional_caregiver_team = [{}]
        // }
        return reqData
    }
    const handleDeleteMedCon = (id) => {

        if (medicalConditions.length === 1) {
            // condition_${val.id}
            // dosage_frequency_${val.id}
            // medicine_name_${val.id}
            setValue(`condition_${id}`, "");
            setValue(`dosage_frequency_${id}`, "");
            setValue(`medicine_name_${id}`, "");
            setMedicalConditions([{ id: Date.now().toString(), source: "application" }])
        } else {
            setMedicalConditions(medicalConditions.filter(val => val.id !== id))
        }
    }

    return (
        <>
            <div className="px-19-scale-mob md:px-19-scale-924 pt-50-scale-mob md:pt-40-scale-924 md:w-[100%] ">

                <div className="px-20-scale-mob md:px-41-scale-924 w-[100%] card pt-20-scale-mob md:pt-20-scale-924 pb-30-scale-mob md:pb-30-scale-924 flex flex-col md:w-[80%]  mx-auto">
                    <p className="text-20-scale-mob md:text-20-scale-924 font-[600] ">Medical Conditions?
                    {/* <span className="text-[#FF4664] pl-[5px]">*</span> */}
                    </p>
                    <div className="flex flex-wrap gap-y-[25px] flex gap-[10px] checkbox-group " onChange={handleCheckboxChange}>

                        {cheboxlabels.map(val =>
                            <div className='w-[45%] md:w-[30%] items-center flex gap-[10px]' key={val}>
                                <input type="checkbox" name={"medical_history"} value={val} className="w-[30px] h-[30px] custom-checkbox" checked={state.medical_history && state.medical_history.some(value => value.name === val)} />
                                <label htmlFor="medical-info" className="text-14-scale-mob md:text-14-scale-924 " >{val}</label>
                            </div>
                        )}
                    </div>


                    {state.medical_history && state.medical_history.some(val => val.name === "Other Conditions") && <div className="md:w-[85%]">
                        <GlobalInput name="other_conditions" label="Other conditions" control={control} labelStyles={"text-16-scale-mob md:text-16-scale-924 font-[600]"} className={"h-40-scale-924-mob md:!h-40-scale-924"} />
                    </div>}
                    {state.medical_history && state.medical_history.map(val => {
                        if (val.name === "None" || val.name === "Other Conditions") return null
                        else {
                            return (
                                <div className="flex md:w-[80%] gap-x-[10px] items-center" key={val.name}>
                                    <p className="text-16-scale-mob md:text-16-scale-924 w-[50%]">How long do you have {val.name} ?
                                    {/* <span className="text-[#FF4664] pl-[5px]">*</span> */}
                                    </p>
                                    <div className="flex gap-x-[10px] items-center">
                                        <div className="w-[90px] ">
                                            <GlobalInput control={control} name={val.name}  type="number" />

                                        </div>
                                        <p className="text-16-scale-mob md:text-16-scale-924 ">In years</p>
                                    </div>
                                </div>)
                        }
                    })}

                    <hr className={"bg-[black] text-[black] h-[3px]"} />
                    {/* medication details */}
                    <p className="text-20-scale-mob md:text-20-scale-924 font-[600] ">Medication? (Optional)</p>
                    {medicalConditions.map((val, index) => <div className="flex justify-between w-[95%] items-center flex-wrap" key={val.id}>
                        <div className="w-[100%] md:w-[30%]">
                            {/* <GLobalSelect control={control} name={`condition_${val.id}`} label="condition" labelStyles={"text-20-scale-mob md:text-16-scale-924  "} className={"h-50-scale-mob md:!h-40-scale-924"}  minHeight="clamp(30px,calc((100vw / 1675) * 40),80px)" options={state.condition_options || []} onFocus={handleMedicalOption} screen="medicine_info" /> */}
                            <GlobalCreatableSelect control={control} name={`condition_${val.id}`} label="Condition" labelStyles={"text-20-scale-mob md:text-16-scale-924  "} className={"h-50-scale-mob md:!h-40-scale-924"} minHeight="clamp(30px,calc((100vw / 1675) * 40),80px)" options={state.condition_options || []} onFocus={handleMedicalOption} screen="medicine_info" />

                        </div>
                        <div className="w-[100%] md:w-[30%]">
                            <GlobalInput control={control} name={`medicine_name_${val.id}`} label="Medicine Name" labelStyles={"text-20-scale-mob md:text-16-scale-924"} className={"h-40-scale-924-mob md:!h-40-scale-924  "} />
                        </div>
                        <div className="w-[100%] md:w-[30%]">
                            <GLobalSelect name={`dosage_frequency_${val.id}`} label="Dosage Frequency" control={control} options={[{ label: "Once a day", value: "Once a day" }, { label: "Twice a day", value: "Twice a day" }, { label: "Thrice a day", value: "Thrice a day" }, { label: "On need bases", value: "On need bases" }]}
                                labelStyles={"text-20-scale-mob md:text-16-scale-924"} className={"h-50-scale-mob md:!h-40-scale-924"} minHeight="clamp(30px,calc((100vw / 1675) * 40),80px)" screen="medicine_info" />
                        </div>
                        <div className='pt-[45px] flex gap-[20px]'>
                            <button onClick={() => handleDeleteMedCon(val.id)} ><DeleteIcon /></button>
                            {index === medicalConditions.length - 1 && <button onClick={handleAddMedicalCondition}><AddFillIcon /></button>}
                        </div>
                    </div>)}
                    {/* Professional caregiver*/}
                    <p className="text-20-scale-mob md:text-20-scale-924 font-[600] ">Professional healthcare team  (Optional)</p>
                    {
                        careGiverTeam.map((val, index) =>
                            <div key={val.id}>
                                <div className="flex justify-between w-[95%] items-center flex-wrap">
                                    <div className="w-[100%] md:w-[20%]">
                                        <GLobalSelect name={`expert_type_${val.id}`} label="Expert Type" control={control} options={[{ label: "Primary Physician", value: "Primary Physician" }, { label: "Doctor", value: "doctor" }, { label: "Nurse", value: "nurse" }, { label: "Caregiver", value: "caregiver" }]}
                                            labelStyles={"text-20-scale-mob md:text-16-scale-924  "} className={"h-50-scale-mob md:!h-40-scale-924"} minHeight="clamp(30px,calc((100vw / 1675) * 40),80px)" screen="medicine_info" onChange={(value) => handlePrimaryPhysician(value.value, val.id)} />
                                    </div>

                                    <div className="w-[100%] md:w-[23%]">
                                        <GlobalInput control={control} name={`expert_name_${val.id}`} label="Expert Name" labelStyles={"text-20-scale-mob md:text-16-scale-924 "} className={"h-40-scale-924-mob md:!h-40-scale-924  "} />
                                    </div>
                                    <div className="w-[100%] md:w-[23%]">
                                        <GlobalInput control={control} name={`expertise_${val.id}`} label="Expertise" labelStyles={"text-20-scale-mob md:text-16-scale-924"} className={"h-40-scale-924-mob md:!h-40-scale-924  "} />
                                    </div>
                                    <div className="w-[100%] md:w-[23%]">
                                        <GlobalInput name={`phone_number_${val.id}`} label="Expert Number" control={control} className={"h-50-scale-mob md:!h-40-scale-924"}
                                            rules={{
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: 'Please Enter Valid Phone Number',
                                                }
                                            }} errors={errors[`phone_number_${val.id}`]} phone_number={"true"} />
                                        {/* <GlobalInput control={control} name="phone_number" label="Phone Number" required={true} rules={{ required: "Phone Number is required" }} errors={errors.phone_number} /> */}
                                    </div>
                                    <div className='pt-[45px] flex gap-[24px]'>
                                        <button onClick={() => handleDeleteCareGiverTeam(val.id)}><DeleteIcon /></button>
                                        {index === careGiverTeam.length - 1 && <button onClick={handleAddCareGiverTeam}><AddFillIcon /></button>}
                                    </div>


                                </div>
                                {showCheckbox.includes(val.id) && <div className='flex gap-[10px] items-center pt-[30px]'>
                                    <input type="checkbox" className="w-[30px] h-[30px] custom-checkbox" checked={careGiverType.includes(val.id)} onChange={() => handleCGcheckbox(val.id)} />
                                    <label htmlFor="medical-info" className="text-14-scale-mob md:text-14-scale-924 " >In the case of an emergency situation, I agree to share my health updates with this contact .</label>
                                </div>
                                }
                            </div>

                        )

                    }
                </div>
            </div>
            <div className="pt-[20px]">
                <FormFooter onNextClick={methods.handleSubmit(onSubmit)} back={true} loading={loading} onClose={onCloseForm} onBack={handleBackClick} />
            </div>
        </>
    )
}

export default MedicalInfo