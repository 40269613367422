import React from "react";

function DashboardIcon({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
    //   width="25"
    //   height="24"
      fill="none"
      viewBox="0 0 25 24"
className={`w-25-scale-mob md:w-25-scale h-24-scale-mob md:h-24-scale ${className}`}
    >
      <path
        // stroke="#141522"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M17.668 10h2c2 0 3-1 3-3V5c0-2-1-3-3-3h-2c-2 0-3 1-3 3v2c0 2 1 3 3 3zM5.668 22h2c2 0 3-1 3-3v-2c0-2-1-3-3-3h-2c-2 0-3 1-3 3v2c0 2 1 3 3 3zM6.668 10a4 4 0 100-8 4 4 0 000 8zM18.668 22a4 4 0 100-8 4 4 0 000 8z"
      ></path>
    </svg>
  );
}

export default DashboardIcon;