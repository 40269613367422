import * as React from "react";

const MenuIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 50 50"
  >
    <path d="M0 7.5v5h50v-5Zm0 15v5h50v-5Zm0 15v5h50v-5Z"></path>
  </svg>
);

export default MenuIcon;
