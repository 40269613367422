import React, { useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form';
import GlobalInput from '../../components/GlobalInput';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux"
import { sendOtp, submitOtp } from '../../redux/actions/authactions';
import { toast } from 'react-toastify';
import Users from '../users/Users';
const Login = () => {
  const [loading, setLoading] = useState(false)
  const token = localStorage.getItem("token")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [showOtp, setShowOtp] = useState(false)
  const methods = useForm({ mode: "all" });
  const { control, formState: { errors }, setValue, getValues } = methods;

  const handleOtpChange = (value, index) => {
    if (value.match(/^\d*$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp.length - 1 && value) {
        // Move focus to the next input box
        focusOnNextInput(index + 1);
      }
    }
  };

  const focusOnNextInput = (index) => {
    const nextInputId = `otp-input-${index}`;
    const nextInput = document.getElementById(nextInputId);

    if (nextInput) {
      // Focus on the next input if it exists
      nextInput.focus();
    } else {
      // Handle the case where the next input does not exist
      console.log(`Input with ID ${nextInputId} not found.`);
      // toast.error("Please Enter Valid OTP");
    }
  };
  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" || e.keyCode === 8) {
      if (otp[index] === "") {
        // Move focus to the previous input box on Backspace or Backspace key code (8)
        if (index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
        }
      }
    } else if (e.key === "Delete" || e.keyCode === 46) {
      setOtp((prevOtp) => {
        prevOtp[index] = "";
        return [...prevOtp];
      });

      // Move focus to the next input box on Delete or Delete key code (46)
      if (index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        nextInput.focus();

        // Place the cursor in front of the value
        nextInput.selectionStart = 0;
        nextInput.selectionEnd = 0;
      }
    } else if (e.key === "ArrowLeft" || e.keyCode === 37) {
      // Move focus to the previous input box on left arrow key or left arrow key code (37)
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    } else if (e.key === "ArrowRight" || e.keyCode === 39) {
      // Move focus to the next input box on right arrow key or right arrow key code (39)
      if (index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    const pastedValues = pastedData.split("");

    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      let currentIndex = index;

      pastedValues.forEach((value) => {
        if (currentIndex < newOtp.length && value.match(/^\d*$/)) {
          newOtp[currentIndex] = value;
          currentIndex++;
        }
      });
      if (currentIndex <= newOtp.length) {
        focusOnNextInput(currentIndex - 1);
      }
      return newOtp;
    });

    // Move focus to the next input box
    focusOnNextInput(index + pastedValues.length);
  };
  const onSubmit = async (data) => {
    try {
      setLoading(true)
      await dispatch(sendOtp({ phone_number: `91${data.phone}` }));
      setShowOtp(true);

    } catch (err) {
      console.log(err.message)
      toast.error(err.message)
    }
    setLoading(false)
  }
  //submit otp
  const handleSubmit = async () => {

    const joinedOtp = otp.join("")
    try {
      setLoading(true)
      const resData=await dispatch(submitOtp({ phone_number: `91${getValues("phone")}`, otp: joinedOtp }));
      localStorage.setItem("token",resData.token)
           localStorage.setItem("id",resData.id);
           localStorage.setItem("name",resData.username);
           localStorage.setItem("is_staff",resData.is_staff);
           localStorage.setItem("phone_number",resData.phone_number);
      // navigate("/users");
      window.location.reload(false)
    } catch (err) {
      console.log(err, err.message);
      toast.error(err.message)
    }
    setLoading(false)
    // console.log(otp1)
  }
  useEffect(() => {
    if (token) {
      navigate("/users")
    }
  }, [token])
  // if(token){
  //   return <Users/>
  // }

  return (
    <div className="flex items-center justify-center h-[100vh]">
      <div className="shadow-lg w-[500px] max-h-[500px] p-[20px] flex flex-col gap-y-[20px]">
        <h1 className="text-2xl font-[600] ">Login </h1>
        <GlobalInput control={control} name="phone" label="Phone Number :"
          required={true} rules={{
            required: "Phone Number is required", pattern: {
              value: /^\d{10}$/,
              message: 'Please Enter Valid Phone Number',
            }
          }} errors={errors.phone} phone_number={true} />
        <button className="h-[45px] bg-[black] text-[white] w-[200px] self-center rounded-[4px]" onClick={methods.handleSubmit(onSubmit)} disabled={loading || showOtp}>{!showOtp && loading ? <span className="loading loading-spinner loading-sm"></span> : "Send Otp"}</button>

        {showOtp && (
          <div>

            <div className="flex gap-x-[20px] items-center justify-center pt-[20px]">
              {otp.map((value, index) => (
                <input
                  className="w-[50px] h-[50px] border-[1px] border-[black] text-center rounded-[4px] "
                  // label="otp"
                  onPaste={(e) => handlePaste(e, index)}

                  onKeyDown={(e) => handleBackspace(e, index)}

                  key={index}
                  id={`otp-input-${index}`}
                  value={value}
                  onChange={(e) => handleOtpChange(e.target.value, index)}
                  maxLength={1}
                />
              ))}
            </div>
          </div>)
        }
        {showOtp && (<button className="h-[50px] bg-[black] text-[white] w-[200px] self-center rounded-[4px]" onClick={handleSubmit} disabled={loading}>{loading ? <span className="loading loading-spinner loading-sm"></span> : "Submit"}</button>)}
      </div>
    </div>
  )
}

export default Login